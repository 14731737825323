import React, { useState, useEffect } from 'react';
import "./HeaderMobile.css";
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import chat from '../assets/chat.png';
import { auth, db } from '../firebaseConfig.ts';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import HomeBottomActive from '../assets/HomeBottomActive.svg'
import HomeBottom from '../assets/HomeBottom.svg'
import ChatBottomActive from '../assets/ChatBottomActive.png'
import ChatBottom from '../assets/ChatBottom.png'
import SerachBottomActive from '../assets/SearchBottomActive.png'
import SearchBottom from '../assets/SearchBottom.png'
import Premiumicon from '../assets/Premiumicon.png'
import ProfileBottom from '../assets/brokerpfp.png'

interface User {
  email?: string | null;
}

interface UserData {
    pfp: string
}

function BrokerHeaderMobile() {
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation(); 
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const isActive = (path: string) => location.pathname === path;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const surveysCol = collection(db, "Surveys");
        const q = query(surveysCol, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userSurveyData = querySnapshot.docs[0].data();
          setUserData(userSurveyData as UserData);
        } else {
          console.log("No matching documents found!");
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  return (
    <div className='header-mobile'>
      <div className='header-mobile-container'>
          <Link to="/broker/search">
          {isActive('/broker/search') ? <img alt='Bottom Nav Icon' className='header-mobile-menu-item' src={SerachBottomActive} /> : <img alt='Bottom Nav Icon' className='header-mobile-menu-item' src={SearchBottom} />}
          </Link>
          <Link to="/chat/manager">
          {isActive('/chat/admin') ? <img alt='Bottom Nav Icon' className='header-mobile-menu-item' src={ChatBottomActive} /> : <img alt='Bottom Nav Icon' className='header-mobile-menu-item' src={ChatBottom} />}
          </Link>

            <Link to="/login"><img alt='Bottom Nav Icon' className='header-mobile-menu-item' src={ProfileBottom} /></Link>

      </div>
    </div>
  );
}

export default BrokerHeaderMobile;
