import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db, auth } from '../firebaseConfig.ts';

const RequireBroker = ({ children }) => {
  const [isBroker, setIsBroker] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserRole = async (userId) => {
        const apartmentsRef = collection(db, "Surveys");
        const q = query(apartmentsRef, where("uid", "==", userId), where("role", "==", "broker"));
        const querySnapshot = await getDocs(q);
        const isUserBroker = !querySnapshot.empty;
        setIsBroker(isUserBroker);

        if (!isUserBroker) {
            navigate('/login'); // Adjust this as necessary
        }
        };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkUserRole(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return isBroker ? children : null;
};

export default RequireBroker;
