import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig.ts'; // Adjust the import path as necessary
import { onAuthStateChanged, signOut } from "firebase/auth";
import './index.css'
import { collection, query, where, getDocs } from "firebase/firestore";
import Header from '../../components/Header.tsx';
import {  useNavigate } from 'react-router-dom';
import HeaderMobile from '../../components/HeaderMobile.tsx';
import domToImage from 'dom-to-image';
import ReactDOM from 'react-dom';

interface UserData {
    phone: string;
    fullname: string;
    citizenship: string;
    adults: string;
    kids: string;
    placeOfWork: string;
    position: string;
    salary: string;
    unemployed: boolean;
    cityID: boolean;
    driverLicense: boolean;
    ead: boolean;
    ssn: boolean;
    previosLandlordLetter: boolean;
    creditScore: string;
    noCreditScore: boolean;
    incomeTax: string;
    noIncomeTax: boolean;
    savings: string;
    lookingBedrooms: string,
    lookingArea: [],
    lookingPrice: string,
    pfp: string,
}

interface ApplyData {
  id: string;
  userId: string;
  apartmentId: string;
  status: string;
  created_at: Date; 
  location: string;
  title: string;
  image: string;
  price: string;
  phone: string;
  broker: string;
}


function Profile() {

  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();
  const [applies, setApplies] = useState<ApplyData[]>([]);
  const [isPremium, setIsPremium] = useState(false);


  useEffect(() => {
    let unsubscribeFromAuthState = () => {};
  
    const checkUserSubscription = async (uid) => {
      const subscriptionsRef = collection(db, "customers", uid, "subscriptions");
      const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
      const subscriptionSnapshot = await getDocs(q);
  
      setIsPremium(!subscriptionSnapshot.empty); // Sets premium status based on subscription documents
    };
  
    unsubscribeFromAuthState = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await checkUserSubscription(user.uid); // First, check if the user is a premium member
  
        // Then, fetch user-specific data, like survey responses or other profile details
        console.log("Querying documents for UID:", user.uid);
        const surveysCol = collection(db, "Surveys");
        const q = query(surveysCol, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userSurveyData = querySnapshot.docs[0].data();
          setUserData(userSurveyData as UserData); // Sets the user data state with fetched data
        } else {
          console.log("No matching documents found!");
          setUserData(null);
        }
      } else {
        setIsPremium(false); // Reset premium status if there's no user
        setUserData(null); // Reset user data if no user is authenticated
        setApplies([]); // Clear applies data if no user is authenticated
      }
    });
  
    return () => unsubscribeFromAuthState(); // Cleanup function to unsubscribe from auth state changes
  }, []);


  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {

        const appliesRef = collection(db, "Applies");
        const q = query(appliesRef, where("userId", "==", user.uid));
        const appliesSnapshot = await getDocs(q);

        const fetchedApplies = appliesSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }) as ApplyData);

        setApplies(fetchedApplies);
      } else {
        setUserData(null);
        setApplies([]);
      }
    });
}, []);
  


  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
      navigate('/')
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const surveysCol = collection(db, "Surveys");
        const q = query(surveysCol, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userSurveyData = querySnapshot.docs[0].data();
          setUserData(userSurveyData as UserData);
        } else {
          console.log("No matching documents found!");
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  if (!userData) {
    return <div></div>;
  }
  
  const handleViewApartment = (apartmentId) => {
    navigate(`/apartment/${apartmentId}`);
  };


  const UserInfoCard = ({ user }) => {
    return (
      <div style={{ padding: '50px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '1000px', margin: '10px auto', fontFamily: 'Arial, sans-serif' }}>
        <img src={user.pfp} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover' }}/>
        <h2 style={{ fontSize: '62px', color: '#333' }}>{user.fullname}</h2>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Citizenship:</strong> {user.citizenship}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Tenants: </strong>{`${Number(user.adults) + Number(user.kids)} (${user.kids} kids)`}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Job Place:</strong> {user.placeOfWork}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Position:</strong> {user.position}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Salary:</strong> ${user.salary}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Credit Score:</strong> {user.creditScore}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Income Tax:</strong> ${user.incomeTax}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Savings:</strong> ${user.savings}</p>
      </div>
    );
  };
  

  const downloadUserInfoAsImage = async () => {
    if (!userData) {
      console.error("User data not available.");
      return;
    }
  
    // Temporarily render the UserInfoCard component for the user
    const tempDiv = document.createElement('div');
    document.body.appendChild(tempDiv);
  
    // You're directly passing userData to UserInfoCard since it's already in your state
    ReactDOM.render(<UserInfoCard user={userData} />, tempDiv);
  
    try {
      const dataUrl = await domToImage.toPng(tempDiv, {});
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `profile-info-${userData.fullname.replace(/\s+/g, '-').toLowerCase()}.png`; // Create a friendly filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Could not generate image:', err);
    }
  
    // Cleanup
    document.body.removeChild(tempDiv);
  };
  

  return (
    <div className='profile-container'>
    <Header />
    <HeaderMobile />
        <div className='profile-first'>
          <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
            <img alt='Profile image' src={userData.pfp} style={{width: '70px', height: '70px', objectFit: 'cover', borderRadius: '50%', border: '4px solid #474EB5'}} />
            <div className='apartments-title'>{userData.fullname}</div>
          </div>
            { isPremium ? (<div className='isPremium'>Premium</div>) : (<div className='isBasic'>Basic</div>)}
        </div>
        
        <div className='apartment-details-card-profile'>
            <div className='apartment-details-grid'>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Fullname</div>
                  <div className='apartment-details-card-row-item-title'>{userData.fullname}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Phone</div>
                  <div className='apartment-details-card-row-item-title'>{userData.phone}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Citizenship</div>
                  <div className='apartment-details-card-row-item-title'>{userData.citizenship}</div>
                </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Tenants</div>
                  <div className='apartment-details-card-row-item-title'>
                  {`${Number(userData.adults) + Number(userData.kids)} tenants (${userData.adults} adults, ${userData.kids} kids)`}  
                  </div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Place of job</div>
                  <div className='apartment-details-card-row-item-title'>{userData.placeOfWork}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Position</div>
                  <div className='apartment-details-card-row-item-title'>{userData.position}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                <div className='apartment-details-card-row-item-label'>Salary</div>
                <div className='apartment-details-card-row-item-title'>
                    {userData.salary === 'Unemployed yet' 
                    ? userData.salary 
                    : `$${userData.salary} / monthly`}
                </div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Credit Score</div>
                  <div className='apartment-details-card-row-item-title'>{userData.creditScore}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                <div className='apartment-details-card-row-item-label'>Income Tax</div>
                <div className='apartment-details-card-row-item-title'>
                    {userData.incomeTax === `Don't Have Income Tax` 
                    ? userData.incomeTax 
                    : `$${userData.incomeTax}/monthly`}
                </div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Savings</div>
                  <div className='apartment-details-card-row-item-title'>${userData.savings}</div>
              </div>
            </div>
        </div>

        <div className='apartments-first'>
            <div className='apartments-title'>What are you looking for</div>
        </div>
        <div className='apartment-details-card-profile'>
            <div className='apartment-details-grid'>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Bedrooms</div>
                  <div className='apartment-details-card-row-item-title'>{userData.lookingBedrooms}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Budget</div>
                  <div className='apartment-details-card-row-item-title'>${userData.lookingPrice}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Preferred Area</div>
                  <div className='apartment-details-card-row-item-title'>
                    {userData.lookingArea.map((area, index) => (
                      <React.Fragment key={index}>
                        {area}{index < userData.lookingArea.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </div>
              </div>
            </div>
        </div>
        <div className='apartments-first'>
            <div className='apartments-title'>Apply History</div>
        </div>
        <div className='apply-history'>
          {applies.map((apply) => (
            <div key={apply.apartmentId} className='apartment-item'>
              <div className='apartment-item-info'>
                <img alt={'Apartment'} className='apartment-item-media' src={apply.image}/>
                <div className='apartment-item-info-column'>
                  <div className='apartment-item-title'>{apply.title}, ${apply.price}</div>
                  <div className='apartment-item-text'>{apply.location}</div>
                </div>
              </div>
              <div className='profile-apartment-item-buttons'>

              {apply.status === 'Pending' && (
                  <div className='profile-pending'>
                    Pending
                  </div>
                )}

              {apply.status === 'Declined' && (
                  <div className='profile-decline'>
                    Declined
                  </div>
                )}

                {apply.status === 'Accepted' && (
                  <div className='broker-user-buttons'>
                  <div className='profile-accept'>
                    Accepted
                  </div>
                  <div className='profile-accept'>
                    <div>{apply.phone}</div>
                  </div>
                  </div>
                )}
              <button className='button-primary' onClick={() => handleViewApartment(apply.apartmentId)}>View</button>
              </div>
            </div>
          ))}
        </div>
            <div className='button-profile-row'>
            {/* { isPremium ? (<button className='button-primary'>Manage subscription</button>) : ""} */}
            <button className='button-primary' onClick={handleLogout}>Logout</button>
            <button className='button-primary' onClick={downloadUserInfoAsImage}>Download survey</button>
        </div>
    </div>
  );
}

export default Profile;
