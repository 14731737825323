import React, { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from '../../firebaseConfig.ts';
import './index.css'
import AdminHeader from '../../components/AdminHeader.tsx';
import { Link } from 'react-router-dom';

interface UserData {
    fullname: string;
    phone: string;
    citizenship: string;
    adults: number;
    kids: number;
    placeOfWork: string;
    position: string;
    salary: string;
    creditScore: string;
    incomeTax: string;
    savings: string;
    uid: string;
    appliedApartments?: AppliedApartment[];
  }

  interface AppliedApartment {
    id: string;
    bedroomAmount: string;
    price: string;
    location: string;
    image: string;
    status: string;
    apartmentId: string;
    broker: string;
  }

function AdminUsers() {

  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  useEffect(() => {
    const fetchUsersAndTheirApplications = async () => {
      const usersQuery = query(collection(db, "Surveys"), where("role", "==", "user"));
      const usersSnapshot = await getDocs(usersQuery);
  
      const usersDataPromises = usersSnapshot.docs.map(async (doc) => {
        const userData = doc.data() as UserData;

        const userAppliesQuery = query(collection(db, "Applies"), where("userId", "==", userData.uid), orderBy("created_at", "desc"));
        const appliesSnapshot = await getDocs(userAppliesQuery);
        const appliedApartments = appliesSnapshot.docs.map(appliesDoc => ({
          id: appliesDoc.id,
          ...appliesDoc.data(),
        })) as AppliedApartment[];
  
        return { ...userData, appliedApartments };
      });
  
      const filteredUsersData = (await Promise.all(usersDataPromises))
        .filter(user => user !== null) as UserData[]; // Explicitly cast the filtered array to UserData[]
  
      setUsersData(filteredUsersData);
    };
  
    fetchUsersAndTheirApplications();
  }, []);
  
  
  const checkIfUserIsPremium = async (userId) => {
    const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
    const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
    const subscriptionSnapshot = await getDocs(q);
    return !subscriptionSnapshot.empty; // True if at least one active or trialing subscription exists
  };
  

  useEffect(() => {
    const fetchActiveUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "Surveys"));
      setActiveUsersCount(querySnapshot.docs.length);
    };

    fetchActiveUsers();
  }, []);

  return (
    <div className='users-container'>
        <AdminHeader />
        <div className='user-first'>
            <div className='users-title'>{activeUsersCount} active users</div>
        </div>
        <div className='users-list'>
        {usersData.map((userData, index) => (
            <div key={index} className='user-details-card-profile'>
                <div className='user-details-grid'>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Fullname</div>
                    <div className='user-details-card-row-item-title'>{userData.fullname}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Phone</div>
                    <div className='user-details-card-row-item-title'>
                    {userData.phone}
                    </div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Citizenship</div>
                    <div className='user-details-card-row-item-title'>{userData.citizenship}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Tenants</div>
                        <div className='user-details-card-row-item-title'>
                        {`${Number(userData.adults) + Number(userData.kids)} (${userData.kids} kids)`}  
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Place of job</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.placeOfWork === 'Unemployed yet' 
                            ? 'No' 
                            : `${userData.placeOfWork}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Position</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.position === 'Unemployed yet' 
                            ? 'No' 
                            : `${userData.position}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Salary</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.salary === 'Unemployed yet' 
                            ? 'No' 
                            : `$${userData.salary}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Credit Score</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.creditScore === `Don't Have Credit Score` 
                            ? 'No'
                            : `${userData.creditScore}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Income Tax</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.incomeTax === `Don't Have Income Tax` 
                            ? 'No'
                            : `$${userData.incomeTax}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Savings</div>
                        <div className='user-details-card-row-item-title'>${userData.savings}</div>
                    </div>
                </div>
                
                <div className='user-applied'>
                    <div className='user-details-card-row-item-label'>Applied apartments</div>
                        <div className='user-applied-row'>
                        {userData.appliedApartments && userData.appliedApartments.length > 0 ? (
                userData.appliedApartments.map((apply) => {

                    let borderColor;
                    switch (apply.status) {
                    case "Accepted":
                        borderColor = "#72BD37";
                        break;
                    case "Declined":
                        borderColor = "#EF4141";
                        break;
                    case "Pending":
                        borderColor = "#E6BF34";
                        break;
                    default:
                        borderColor = "grey"; // Default case for an unknown status
                    }

    return (
      <Link key={apply.id} className='user-applied-apartment' to={`/apartment/${apply.apartmentId}`}>
        <img
          alt='Apartment'
          src={apply.image}
          style={{
            width: '70px',
            height: '45px',
            objectFit: 'cover',
            borderRadius: '8px',
            border: `1px solid ${borderColor}`,
            padding: '2px',
            boxSizing: 'border-box',
          }}
        />
        <div className='user-details-card-row-item'>
          <div className='user-details-card-row-item-title'>{apply.bedroomAmount} bed, ${apply.price}, {apply.broker}</div>
          <div className='user-details-card-row-item-label'>{apply.location.split(',')[0]}</div>
        </div>
      </Link>
    );
  })
) : (
  <div>No apartments applied for.</div>
)}

                        </div>
                </div>    
            </div>
            ))}
        </div>
    </div>

  )
}

export default AdminUsers