import React, { useEffect, useState } from 'react';
import { Timestamp, addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, auth } from '../../firebaseConfig.ts';
import './index.css'
import AdminHeader from '../../components/AdminHeader.tsx';
import { createUserWithEmailAndPassword } from 'firebase/auth';

interface BrokerData {
  id: string; 
  email: string;
  password: string;
  fullname: string;
  phone: string;
  source: string;
  brokerStatus: string;
  created_at: Timestamp;
}

function AdminBrokers() {
  const [usersData, setUsersData] = useState<BrokerData[]>([]);
  
  useEffect(() => {
    const fetchBrokers = async () => {
      const q = query(collection(db, "Surveys"), where("role", "==", "broker"), where("brokerStatus", "==", "Pending"));
      const querySnapshot = await getDocs(q);
      const brokers = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      })) as BrokerData[];
      setUsersData(brokers);
    };

    fetchBrokers();
  }, []);

  const approveBrokerByEmail = async (email: string) => {
    const q = query(collection(db, "Surveys"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (document) => {
      const brokerDocRef = doc(db, "Surveys", document.id);
      await updateDoc(brokerDocRef, {
        brokerStatus: "Verified"
      });

      // After updating the broker status, send an email notification
      const mailRef = collection(db, "mail");
      await addDoc(mailRef, {
        to: [email], // Broker's email
        message: {
          subject: "You've been approved as a broker!",
          text: "Congratulations! You've been approved as a broker. Now you can post apartments, check tenants' apartments. Use our SmartSearch to find qualified tenants by one click.",
          html: `<p>Congratulations! You've been approved as a broker.</p>
          <p>Now you can post apartments, check tenants' apartments, use our SmartSearch to find qualified tenants by one click.</p>
          <p></p>
          <p>Also you are open to use our referral program to start earning with EasyRent!</p>
          <p>Brokers who are in our referral program make around $1,300-2,000/monthly.</p>
          <p></p>
          <p>Visit your dashboard to learn more: https://easyrental-92cda.web.app/broker</p>`
        }
      });
      console.log("Broker approved and notification sent");
    });
  };
  
  
  

  return (
    <div className='users-container'>
        <AdminHeader />
        <div className='user-first'>
            <div className='users-title'>Brokers' Applies</div>
        </div>
        <div className='users-list'>
        {usersData.map((userData, index) => (
            <div key={index} className='user-details-card-profile'>
                <div className='broker-details-grid'>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Fullname</div>
                    <div className='user-details-card-row-item-title'>{userData.fullname}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Phone</div>
                    <div className='user-details-card-row-item-title'>
                    {userData.phone.replace(/ /g, '').replace(/\+/g, '')}
                    </div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Email</div>
                    <div className='user-details-card-row-item-title'>{userData.email}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Status</div>
                    <div className='user-details-card-row-item-title'>{userData.brokerStatus}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Date</div>
                    <div className='user-details-card-row-item-title'>{userData.created_at.toDate().toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                    day: 'numeric',
                    month: 'long',
                    })}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Source</div>
                    <div className='user-details-card-row-item-title'>{userData.source}</div>
                    </div>
                </div>
                <button onClick={() => approveBrokerByEmail(userData.email)} className='button-active'>Approve</button>
            </div>
            ))}
        </div>
    </div>

  )
}

export default AdminBrokers

function fetchBrokers() {
    throw new Error('Function not implemented.');
}
