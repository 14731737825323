import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig.ts'; // Adjust this import path to your Firebase config file
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import AdminHeader from '../../components/AdminHeader.tsx';
import { onAuthStateChanged } from 'firebase/auth';

interface ApartmentFormData {
    title: string;
    description: string;
    price: string;
    bedroomAmount: string;
    documentChecking: string;
    floor: string;
  }

function BrokerUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apartmentData, setApartmentData] = useState<ApartmentFormData>({
    title: '',
    description: '',
    price: '',
    bedroomAmount: '',
    documentChecking: '',
    floor: '',
  });
  const [formData, setFormData] = useState<ApartmentFormData>({
    title: '',
    description: '',
    price: '',
    bedroomAmount: '',
    documentChecking: '',
    floor: '',
  });

  useEffect(() => {
    const fetchApartmentDetails = async () => {
      if (!id) return; // Make sure there's an ID available
      const docRef = doc(db, "Apartments", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data() as ApartmentFormData; // Cast to ApartmentFormData
        setApartmentData(data);
        setFormData(data);
      } else {
        console.log("No such document!");
      }
    };
    fetchApartmentDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) return;
    const docRef = doc(db, "Apartments", id);
    await updateDoc(docRef, {
      ...formData
    });
    navigate('/admin');
  };

  return (
    <div className='admin-container'>
      <AdminHeader />
      <form className='signup-form'>
      <h2>Edit apartment</h2>
      <div className='current-apartment-info'>
          <div></div>
          <div>{apartmentData.title}</div>
          <div>{apartmentData.description}</div>
          <div>Price: ${apartmentData.price}</div>
          <div>Bedrooms: {apartmentData.bedroomAmount}</div>
          <div>Document Checking: {apartmentData.documentChecking}</div>
          <div>Floor: {apartmentData.floor}</div>
      </div>

      <div className='admin-field'>
          <label>Title:</label>
          <input type="text" name="title" onChange={handleChange} required />
        </div>
        <div className='admin-field'>
          <label>Description:</label>
          <textarea name="description" onChange={handleChange} required />
        </div>
        <div className='admin-field'>
          <label>Price:</label>
          <input type="number" name="price" onChange={handleChange} required />
        </div>
        <div className='admin-field'>
          <label>Bedroom Amount:</label>
          <select name="bedroomAmount" onChange={handleChange} required>
            <option value="">Bedrooms</option>
            <option value="room">A room</option>
            <option value="studio">Studio</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div className='admin-field'>
          <label>Document Checking:</label>
          <select name="documentChecking" onChange={handleChange} required>
            <option value="">Document Checking</option>
            <option value="No need">No need</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
        <div className='admin-field'>
          <label>Floor:</label>
          <select name="floor" onChange={handleChange} required>
            <option value="">Floor</option>
            <option value="1st">1st</option>
            <option value="2nd">2nd</option>
            <option value="3rd">3rd</option>
            <option value="Basement">Basement</option>
            <option value="Semi-basement">Semi-basement</option>
            <option value="Walk-in">Walk-in</option>
          </select>
        </div>
        <button type="submit" onClick={handleSubmit}>Update Apartment</button>
      </form>
    </div>
  );
}

export default BrokerUpdate;