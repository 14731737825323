import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig.ts';
import { doc, getDoc, collection, addDoc, serverTimestamp, query, where, getDocs, onSnapshot, Timestamp } from 'firebase/firestore';
import Header from '../../components/Header.tsx';
import './index.css';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebaseConfig.ts';
import subscribeicon from '../../assets/subscripe-icon.png'
import successicon from '../../assets/succeed-icon.png'
import registericon from '../../assets/register-icon.png'
import alreadyicon from '../../assets/already-icon.png'
import HeaderMobile from '../../components/HeaderMobile.tsx';

interface Apartment {
  id: string;
  title: string;
  description: string;
  price: string;
  images: string[];
  bedroomAmount: string;
  documentChecking: string;
  floor: string;
  lat: number;
  lng: number;
  locationString: string;
  broker: string;
  phone: string;
  brokerEmail: string;
  created_at: Timestamp;
  approved: boolean;
}



function ApartmentsPage() {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [applicationCount, setApplicationCount] = useState(0);
  const [userRole, setUserRole] = useState('');
  const { id } = useParams();
  const [apartment, setApartment] = useState<Apartment & { id: string } | null>(null);
  const [mainImage, setMainImage] = useState<string>('');
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [showAlreadyPopup, setShowAlreadyPopup] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    let unsubscribe = () => {};

    const checkSubscriptionStatus = () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const subscriptionQuery = query(collection(db, "customers", userId, "subscriptions"), where('status', 'in', ['trialing', 'active']));

        unsubscribe = onSnapshot(subscriptionQuery, (snapshot) => {
          // Here we check if there's at least one document in the snapshot
          if (!snapshot.empty) {
            const subscriptionData = snapshot.docs[0].data(); // Get data of the first subscription
            console.log(snapshot.docs[0].id, ' => ', subscriptionData);
            setHasActiveSubscription(true);
          } else {
            setHasActiveSubscription(false);
          }
        });
      } else {
        setHasActiveSubscription(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        checkSubscriptionStatus();
      } else {
        setHasActiveSubscription(false);
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  useEffect(() => {
    // Authenticate and set user role
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // Create a query against the collection.
        const surveysQuery = query(collection(db, "Surveys"), where("uid", "==", user.uid));
        getDocs(surveysQuery).then((querySnapshot) => {
          // Assuming there's only one document per user in the Surveys collection
          if (!querySnapshot.empty) {
            const userSurveyData = querySnapshot.docs[0].data();
            setUserRole(userSurveyData.role);
          } else {
            console.log("No matching documents in 'Surveys' collection");
            // Optionally handle the case where there is no matching document
          }
        });
      }
    });
  }, []);
  

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDiD15XJ8w3qW9C2KEkTQNx_qaeUF7-eDI",
  });
  
  const handleImageClick = (imageUrl) => {
    setMainImage(imageUrl);
  };

  useEffect(() => {
    // Fetch apartment and navigate if not approved and user role is 'user'
    const fetchApartment = async () => {
      if (!id) return;
      const docRef = doc(db, "Apartments", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const fetchedApartment = { id: docSnap.id, ...(docSnap.data() as Omit<Apartment, 'id'>) };
        setApartment(fetchedApartment);

        if (fetchedApartment.images && fetchedApartment.images.length > 0) {
          setMainImage(fetchedApartment.images[0]);
        }

        // Navigate if apartment is not approved and user is not logged in or has a 'user' role
        if (!fetchedApartment.approved && (!auth.currentUser || userRole === 'user')) {
          navigate('/apartments');
        }
      } else {
        console.log("No such apartment found!");
      }
    };

    fetchApartment();
  }, [id, userRole]);

  useEffect(() => {
    if (!id) return; // Exit if there's no apartment ID available
  
    const fetchApplicationCount = async () => {
      const appliesRef = collection(db, "Applies");
      const q = query(appliesRef, where("apartmentId", "==", id));
      const querySnapshot = await getDocs(q);
      setApplicationCount(querySnapshot.size);
    };
  
    fetchApplicationCount();
  }, [id]);

  



  if (!apartment) {
    return <div>Loading...</div>;
  }

  if (!isLoaded) return <div>Loading...</div>;

  const containerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '6px'
  };

  const closeshowSubscribePopup = () => {
    setShowSubscribePopup(false);
  };

  const closeshowSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const closeshowAuthPopup = () => {
    setShowAuthPopup(false);
  };

  const closeshowAlreadyPopup = () => {
    setShowAlreadyPopup(false);
  };

  const askManager = () => {
    const preFilledMessage = encodeURIComponent("Hi, I would like to know more about this apartment!");
    // Split the path by slashes, encode each part, and then join with '%2F'
    const encodedPhotoPath = apartment.images[0].split('/').map(encodeURIComponent).join('%2F');
    const apartmentDetails = {
      photo: encodedPhotoPath, // Use the encoded photo path
      bedrooms: encodeURIComponent(formatBedroomAmount(apartment.bedroomAmount)),
      price: encodeURIComponent(apartment.price),
      locationString: encodeURIComponent(apartment.locationString.split(',')[0] || ''),
      link: encodeURIComponent(`/apartment/${apartment.id}`)
    };
    const detailParams = Object.keys(apartmentDetails)
      .map(key => `${key}=${apartmentDetails[key]}`)
      .join('&');
  
    navigate(`/chat/manager?message=${preFilledMessage}&${detailParams}`);
};

  
  

  const formatBedroomAmount = (bedroomAmount) => {
    if (bedroomAmount === 'studio') {
      return 'Studio';
    } else if (bedroomAmount === 'room') {
      return 'A room';
    } else {
      return `${bedroomAmount} bedroom`;
    }
  };

  const formatFloor = (floor) => {
    if (floor === 'Walk-in') {
      return 'Walk-in';
    } else if (floor === 'Semi-basement') {
      return 'Semi-basement';
    } else if (floor === 'Basement') {
      return 'Basement';
    } else {
      return `${floor} floor`;
    }
  };


  const handleApplyClick = async () => {
    if (!auth.currentUser) {
      setShowAuthPopup(true);
      return; // Guard clause in case there's no authenticated user
    }
  const userId = auth.currentUser.uid;
  const apartmentId = id; // Assuming 'id' comes from useParams() and represents the apartment's ID

  // Query for existing application
  const appliesRef = collection(db, "Applies");
  const q = query(appliesRef, where("userId", "==", userId), where("apartmentId", "==", apartmentId));

  if (!hasActiveSubscription) {
    setShowSubscribePopup(true);
    return;
  }

  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    setShowAlreadyPopup(true);
  } else {
    // Fetch user's full name from the Surveys collection
    let userFullName = ""; // Default to empty string if name not found
    const surveysRef = collection(db, "Surveys");
    const surveysQuery = query(surveysRef, where("uid", "==", userId));
    const surveySnapshot = await getDocs(surveysQuery);
    if (!surveySnapshot.empty) {
      const userData = surveySnapshot.docs[0].data();
      userFullName = userData.fullname; // Assuming the field for the user's full name is labeled as 'fullName'
    }

    // Add application document
    await addDoc(appliesRef, {
      userId: userId,
      apartmentId: apartmentId,
      status: "Pending",
      created_at: serverTimestamp(),
      location: apartment.locationString,
      title: apartment.title,
      description: apartment.description,
      documentChecking: apartment.documentChecking,
      floor: apartment.floor,
      image: apartment.images[0],
      price: apartment.price,
      bedroomAmount: apartment.bedroomAmount,
      broker: apartment.broker,
      phone: apartment.phone
    });

    // Send email to the broker
    const mailRef = collection(db, "mail");
    await addDoc(mailRef, {
      to: [apartment.brokerEmail],
      message: {
        subject: "Someone applies to your apartment.",
        text: `${userFullName} applied to your apartment.`,
        html: `<p>${userFullName} applied to your apartment, ${formatBedroomAmount(apartment.bedroomAmount)}, for $${apartment.price}.</p><p>You can view the application here: <a href="https://easyrental-92cda.web.app/broker">https://easyrental-92cda.web.app/broker</a></p>`
      }
    });

    setShowSuccessPopup(true);
    setAlreadyApplied(true);
  }
};

  


  return (
<div className='apartment-page'>
<Header />
<HeaderMobile />
      <div className='apartment-page-title-row'>
      <div className='apartment-page-title'>{formatBedroomAmount(apartment.bedroomAmount)} for rent, {apartment.locationString.split(',')[0]}</div>
      <div className='apartment-details-card-chat-row-item-title'>{applicationCount} users applied</div>
      </div>
      <div className='apartment-details'>
        
        <div className='apartment-details-left'>
        <div className='apartment-details-card-price'>
          <div>${apartment.price} / monthly</div>
        </div>

          <div className='apartment-details-card'>
              <div className='apartment-details-card-row'>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Bedrooms</div>
                  <div className='apartment-details-card-row-item-title'>{formatBedroomAmount(apartment.bedroomAmount)}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Document Checking</div>
                  <div className='apartment-details-card-row-item-title'>{apartment.documentChecking}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Floor</div>
                  <div className='apartment-details-card-row-item-title'>{formatFloor(apartment.floor)}</div>
                </div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Location</div>
                  <div className='apartment-details-card-row-item-title'>{apartment.locationString}</div>
              </div>
              <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Description</div>
                  <div className='apartment-details-card-row-item-title'>{apartment.description}</div>
              </div>
          </div>

          <div className='apartment-details-card-chat'>
              <div className='apartment-details-card-chat-row-item-title'>You can write to us directly about this housing option. Our managers will advise you and help you with documents and renting housing.</div>
              {alreadyApplied ? (
                <button className='button-active' onClick={handleApplyClick}>Applied</button>
              ) : (
                <button className='button-active' onClick={handleApplyClick}>Apply</button>
              )}
              {showAuthPopup && (
                <div className="popup">
                  <img alt="AuthIcon" src={registericon} className='popup-icon'/>
                  <div className='popup-title'>Register or login to apply</div>
                  <Link className='popup-button' to={'/signup'} style={{textDecoration: 'none'}}>Register</Link>
                  <button className='popup-x' onClick={closeshowAuthPopup}>x</button>
                </div>
              )}
              {showSubscribePopup && (
                <div className="popup">
                <img alt="AuthIcon" src={subscribeicon} className='popup-icon'/>
                <div className='popup-title'>Subscribe to EasyRent Premium to apply</div>
                <Link className='popup-button' to={'/pricing'} style={{textDecoration: 'none'}}>Subscribe Now</Link>
                <button className='popup-x' onClick={closeshowSubscribePopup}>x</button>
              </div>
              )}
              {showAlreadyPopup && (
              <div className="popup">
              <img alt="AuthIcon" src={alreadyicon} className='popup-icon'/>
              <div className='popup-title'>You've already applied to this apartment!</div>
              <button className='popup-x' onClick={closeshowAlreadyPopup}>x</button>
              </div>
              )}
              {showSuccessPopup && (
              <div className="popup">
              <img alt="AuthIcon" src={successicon} className='popup-icon'/>
              <div className='popup-title'>You’ve successfully applied!</div>
              <button className='popup-x' onClick={closeshowSuccessPopup}>x</button>
              </div>
              )}
              <button className='button-active-chat' onClick={askManager}>Ask to Manager</button>
          </div>

          <div className='apartment-location-mobile'>
            <h3>Location</h3>
            
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: apartment.lat, lng: apartment.lng }}
                zoom={15}
              >
                <Marker position={{ lat: apartment.lat, lng: apartment.lng }} />
              </GoogleMap>
          
          </div>

          {/* <div className='apartment-details-card-nearby-mobile'>
                    <div className='apartment-details-card-nearby-item'>
                      <div className='apartment-details-card-row-item-title'>School in 3 minutes: {apartment.nearestSchool}</div>
                    </div>
                    <div className='apartment-details-card-nearby-item'>
                      <div className='apartment-details-card-row-item-title'>Train Station in 3 minutes: {apartment.nearestSchool}</div>
                    </div>
                    <div className='apartment-details-card-nearby-item'>
                      <div className='apartment-details-card-row-item-title'>Mall in 3 minutes: {apartment.nearestSchool}</div>
                    </div>
          </div> */}

          <div className='apartment-details-created-date-mobile'>
            Posted: {apartment.created_at.toDate().toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </div>
            </div>


        <div className='apartment-details-right'>
        <div className='image-carousel'>
        <div className='main-image'>
          <img src={mainImage} alt="Main Apartment" className='apartment-media-details' />
        </div>
        <div className='thumbnail-images'>
          {apartment.images.map((image, index) => (
            <img 
              key={index} 
              src={image} 
              alt={`Thumbnail ${index + 1}`} 
              className='apartment-thumbnail-media' 
              onClick={() => handleImageClick(image)} 
            />
          ))}
        </div>
      </div>

      <div className='apartment-location'>
        <h3>Location</h3>
        
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: apartment.lat, lng: apartment.lng }}
            zoom={15}
          >
            <Marker position={{ lat: apartment.lat, lng: apartment.lng }} />
          </GoogleMap>
       
      </div>

      {/* <div className='apartment-details-card-nearby'>
                <div className='apartment-details-card-nearby-item'>
                  <div className='apartment-details-card-row-item-title'>School in 3 minutes: {apartment.nearestSchool}</div>
                </div>
                <div className='apartment-details-card-nearby-item'>
                  <div className='apartment-details-card-row-item-title'>Train Station in 3 minutes: {apartment.nearestTrainStation}</div>
                </div>
                <div className='apartment-details-card-nearby-item'>
                  <div className='apartment-details-card-row-item-title'>Mall in 3 minutes: {apartment.nearestMall}</div>
                </div>
      </div> */}

      <div className='apartment-details-created-date'>
        Posted: {apartment.created_at.toDate().toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </div>
        </div>
      </div>
    </div>
  )
}

export default ApartmentsPage

