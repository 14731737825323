import React, { useState } from 'react'
import Header from '../../components/Header.tsx'
import HeaderMobile from '../../components/HeaderMobile.tsx'
import pricing from '../../assets/pricing-icon.png'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../firebaseConfig.ts';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import './index.css'

function Broker() {
    const [showFormPopup, setShowFormPopup] = useState(false);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        fullname: '',
        phone: '',
        source: '',
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
      };

    const handleFormAppear = () => {
        setShowFormPopup(true);
    }

    const closeForm = () => {
        setShowFormPopup(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
          const user = userCredential.user;

          await addDoc(collection(db, "Surveys"), {
            ...formData,
            uid: user.uid,
            role: 'broker',
            password: formData.password,
            brokerPromocode: '',
            brokerStatus: 'Pending',
            created_at: serverTimestamp(),
          });
          setShowFormPopup(false);
          navigate('/broker')
      
        } catch (error) {
            console.error("Error signing up:", error);
            alert(`Error: ${error.message}`);
        }
      };

  return (
    <div className='broker-signup-container'>
    <Header />
    <HeaderMobile />
<div className='price-left'>
    <img src={pricing} alt='Pricing-icon' style={{width: '70px', objectFit: 'cover'}} />
    <div className='price-title'>Became RE Broker in EasyRent!</div>
    <div className='price-subtitle'>Find qualified clients for your listings by 1 click.</div>
</div>
<div className='popup-container'>
    <div className='popup-broker-signup'>
        <div className='popup-pricing-column'>
            <div className='popup-pricing-title'>EasyRent Broker</div>
            <div className='popup-pricing-price-column'>
                <div className='popup-pricing-adv'>Earn $500+ for activity</div>
                <div className='popup-pricing-adv'>Earn $800+ from promocodes</div>
                <div className='popup-pricing-adv'>Smartsearch: Find right clients by 1 click</div>
                <div className='popup-pricing-adv'>3x incresing monthly revenue</div>
            </div>
        </div>
        <button onClick={handleFormAppear} className='button-active'>
        Sign Up
        </button>
        {showFormPopup && (
                <div className="popup">
                <form onSubmit={handleSubmit} className='signup-form'>
                <div className='admin-field'>
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className='admin-field'>
                <label>Password:</label>
                <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                </div>
                <div className='admin-field'>
                <label>Fullname:</label>
                <input type="text" name="fullname" value={formData.fullname} onChange={handleChange} required />
                </div>
                <div className='admin-field'>
                <label>Phone:</label>
                <input type="number" name="phone" value={formData.phone} onChange={handleChange} required />
                </div>
                <div className='admin-field'>
                <label>Link (Personal website, Telegram channel, Facebook group, etc.)</label>
                <input type="text" name="source" value={formData.source} onChange={handleChange} />
                </div>
                <button type="submit">Submit</button>
                </form>
                <button className='popup-x' onClick={closeForm}>x</button>
              </div>
        )}
    </div>
</div>
</div>
  )
}

export default Broker