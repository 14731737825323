import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig.ts'; // Adjust this import path to your Firebase config file
import { collection, collectionGroup, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import './index.css';
import AdminHeader from '../../components/AdminHeader.tsx';
import { signOut } from 'firebase/auth';

interface Apartment {
  id: string;
  title: string;
  bedroomAmount: string;
  broker: string;
  description?: string;
  price?: string;
  locationString?: string;
  images: string[];
  isActive: boolean;
  apartmentId: string;
  approved: boolean;
}

function AdminDashboard() {
  const navigate = useNavigate();
  const [usersCount, setUsersCount] = useState(0);
  const [paidUsersCount, setPaidUsersCount] = useState(0);
  const [thisMonthEarnings, setThisMonthEarnings] = useState(0);
  const [apartmentsCount, setApartmentsCount] = useState(0);
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [filterActive, setFilterActive] = useState<boolean | null>(true);

  
  const archiveApartment = async (apartmentId: string) => {
    try {
      await updateDoc(doc(db, "Apartments", apartmentId), {
        isActive: false
      });
      setApartments(prevApartments => prevApartments.map(apartment => {
        if (apartment.id === apartmentId) {
          return { ...apartment, isActive: false };
        }
        return apartment;
      }));
      console.log('Apartment archived.')
    } catch (error) {
      console.error("Error archiving apartment:", error);
    }
  };

  const activateApartment = async (apartmentId: string) => {
    try {
      await updateDoc(doc(db, "Apartments", apartmentId), {
        isActive: true
      });
      setApartments(prevApartments => prevApartments.map(apartment => {
        if (apartment.id === apartmentId) {
          return { ...apartment, isActive: true };
        }
        return apartment;
      }));
      console.log('Apartment is active again.')
    } catch (error) {
      console.error("Error archiving apartment:", error);
    }
  };

  useEffect(() => {
    // Use a collection group query to access all 'subscriptions' sub-collections across all 'customers'
    const q = query(collectionGroup(db, "subscriptions"), where("status", "in", ["trialing", "active"]));
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
        let activeSubscriptions = 0;
        let earnings = 0;
  
        snapshot.forEach(() => {
            activeSubscriptions += 1;
            earnings += 20; // Assuming $20 per active subscription
            console.log('Paid user counted');
        });
  
        setPaidUsersCount(activeSubscriptions);
        setThisMonthEarnings(earnings);
    });
  
    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []); // The dependency array is kept empty to run only once on component mount
  
  
  
  const unpaidUsersCount = usersCount - paidUsersCount;


  useEffect(() => {
    const fetchUsersCount = async () => {
      const querySnapshot = await getDocs(collection(db, "Surveys"));
      setUsersCount(querySnapshot.size);
      const querySnapshot2 = await getDocs(collection(db, "Apartments"));
      setApartmentsCount(querySnapshot2.size);
  };

    const fetchApartments = async () => {
      const querySnapshot = await getDocs(collection(db, "Apartments"));
      const apartmentsData: Apartment[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as Omit<Apartment, 'id'> 
      }));
      setApartments(apartmentsData);
    };

    fetchUsersCount();
    fetchApartments();
  }, []);

  const approveApartment = async (apartmentId: string) => {
    try {
      await updateDoc(doc(db, "Apartments", apartmentId), {
        approved: true
      });
      // Optionally update the local state to reflect this change without refetching from Firestore
      setApartments(prevApartments => prevApartments.map(apartment => {
        if (apartment.id === apartmentId) {
          return { ...apartment, approved: true };
        }
        return apartment;
      }));
      console.log('Apartment approved.')
    } catch (error) {
      console.error("Error approving apartment:", error);
    }
  };
  

  const uploadPage = () => {
    navigate('/admin-upload');
  };

  const filterApartments = (isActive: boolean | null) => {
    setFilterActive(isActive);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
      navigate('/')
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <div className='dashboard-container'>
      <AdminHeader />
      <div className='dashboard-welcome'>
        <div className='apartments-title'>Welcome, admin!</div>
        <button className='button-primary' onClick={handleLogout}>Logout</button>
      </div>
      <div className='dashboard-cards'>
        <Link className='dashboard-card' to={'/admin-users'}>
            <div className='dashboard-card-title'>Active users</div>
            <div className='dashboard-card-text'>{usersCount}</div>
        </Link>

        <Link className='dashboard-card' to={'/admin-users'}>
            <div className='dashboard-card-title'>Paid users</div>
            <div className='dashboard-card-text'>{paidUsersCount}</div>
        </Link>

        <Link className='dashboard-card' to={'/admin-users'}>
            <div className='dashboard-card-title'>Unpaid users</div>
            <div className='dashboard-card-text'>{unpaidUsersCount}</div>
        </Link>

        <div className='dashboard-card'>
            <div className='dashboard-card-title'>Total earned</div>
            <div className='dashboard-card-text'>${thisMonthEarnings.toFixed(2)}</div>
        </div>

        <div className='dashboard-card'>
            <div className='dashboard-card-title'>Apartments</div>
            <div className='dashboard-card-text'>{apartmentsCount}</div>
        </div>

        <Link className='dashboard-card' to={'/admin-brokers'}>
            <div className='dashboard-card-title'>Broker applies</div>
            <div className='dashboard-card-text'>click</div>
        </Link>
      </div>
      <div className='apartment-item-buttons'>
      <button className='button-active' onClick={uploadPage}>Upload new listing</button>
      <button className='button-primary' onClick={() => filterApartments(true)}>Active listings</button>
      <button className='button-primary' onClick={() => filterApartments(false)}>Archived listings</button>
      </div>


      <div className='apartments-list'>
      {apartments
          .filter(apartment => apartment.approved === false)
          .map((apartment) => (
            <div key={apartment.id} className='apartment-item'>
              <div className='apartment-item-info'>
                <img alt={'Apartment'} className='apartment-item-media' src={apartment.images && apartment.images.length > 0 ? apartment.images[0] : 'https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultphoto.png?alt=media&token=c5f529c8-af52-429e-9ce2-290f9910d31c'} />
                <Link className='apartment-item-info-column' style={{textDecoration: 'none'}} to={`/apartment/${apartment.id}`} >
                  <div className='apartment-item-title'>{apartment.bedroomAmount} bedrooms, ${apartment.price}</div>
                  <div className='apartment-item-text'>{apartment.locationString?.split(',')[0]}, {apartment.broker}</div>
                  <div className='archived-text'>{(!apartment.approved ? 'Pending' : 'Approved')}</div>
                </Link>
              </div>
              <div className='apartment-item-buttons'>
                <button className='button-primary' onClick={() => approveApartment(apartment.id)}>Approve</button>
                <button className='button-primary' onClick={() => navigate(`/admin-edit/${apartment.id}`)}>Edit</button>
              </div>
            </div>
        ))}
      </div>
      

      <div className='apartments-list'>
      {apartments
          .filter(apartment => apartment.isActive === filterActive && apartment.approved === true)
          .map((apartment) => (
            <div key={apartment.id} className='apartment-item'>
              <div className='apartment-item-info'>
                <img alt={'Apartment'} className='apartment-item-media' src={apartment.images && apartment.images.length > 0 ? apartment.images[0] : 'https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultphoto.png?alt=media&token=c5f529c8-af52-429e-9ce2-290f9910d31c'} />
                <Link className='apartment-item-info-column' style={{textDecoration: 'none'}} to={`/apartment/${apartment.id}`} >
                  <div className='apartment-item-title'>{apartment.bedroomAmount} bedrooms, ${apartment.price}</div>
                  <div className='apartment-item-text'>{apartment.locationString?.split(',')[0]}, {apartment.broker}</div>
                  {filterActive !== true && <div className='archived-text'>Archived</div>}
                  {filterActive !== false && <div className='active-text'>Active</div>}
                </Link>
              </div>
              <div className='apartment-item-buttons'>
                {filterActive !== true && <button className='button-primary'onClick={() => activateApartment(apartment.id)}>Activate</button>}
                {filterActive !== false && <button className='button-primary'onClick={() => archiveApartment(apartment.id)}>Archive</button>}
                {filterActive !== false &&  <button className='button-primary' onClick={() => navigate(`/admin-edit/${apartment.id}`)}>Edit</button>}
                {!apartment.approved && <button className='button-primary' onClick={() => approveApartment(apartment.id)}>Approve</button>}
              </div>
            </div>
        ))}
      </div>
    </div>
  )
}

export default AdminDashboard