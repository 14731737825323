import React, { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from '../../firebaseConfig.ts';
import '../AdminUsers/index.css'
import AdminHeader from '../../components/AdminHeader.tsx';
import BrokerHeader from '../../components/BrokerHeader.tsx';
import BrokerHeaderMobile from '../../components/BrokerHeaderMobile.tsx';

interface UserData {
    fullname: string;
    phone: string;
    citizenship: string;
    adults: number;
    kids: number;
    placeOfWork: string;
    position: string;
    salary: string;
    creditScore: string;
    incomeTax: string;
    savings: string;
    uid: string;
    pfp: string;
  }

function BrokerUsers() {

  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  useEffect(() => {
    const fetchUsersAndTheirApplications = async () => {
      const usersQuery = query(collection(db, "Surveys"), where("role", "==", "user"));
      const usersSnapshot = await getDocs(usersQuery);
  
      const usersDataPromises = usersSnapshot.docs.map(async (doc) => {
        const userData = doc.data() as UserData;
        const isPremium = await checkIfUserIsPremium(userData.uid);
        if (!isPremium) {
          return null;
        }
  
        return { ...userData};
      });
  
      // Filter out any nulls and ensure TypeScript knows the array consists only of UserData objects
      const filteredUsersData = (await Promise.all(usersDataPromises))
        .filter(user => user !== null) as UserData[]; // Explicitly cast the filtered array to UserData[]
      
      setActiveUsersCount(filteredUsersData.length);
      setUsersData(filteredUsersData);
    };
    
    fetchUsersAndTheirApplications();
  }, []);
  
  
  const checkIfUserIsPremium = async (userId) => {
    const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
    const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
    const subscriptionSnapshot = await getDocs(q);
    return !subscriptionSnapshot.empty; // True if at least one active or trialing subscription exists
  };
  



  return (
    <div className='users-container'>
        <BrokerHeader />
        <BrokerHeaderMobile />
        <div className='user-first'>
            <div className='users-title'>{activeUsersCount} active tenants</div>

        </div>
        <div className='users-list'>
        {usersData.map((userData, index) => (
            <div key={index} className='user-details-card-profile'>
                <div className='user-details-grid'>
                    <div className='user-details-card-row-item'>
                        <img src={userData.pfp} className='broker-user-pfp' />
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Fullname</div>
                    <div className='user-details-card-row-item-title'>{userData.fullname}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Citizenship</div>
                    <div className='user-details-card-row-item-title'>{userData.citizenship}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Tenants</div>
                        <div className='user-details-card-row-item-title'>
                        {`${Number(userData.adults) + Number(userData.kids)} (${userData.kids} kids)`}  
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Place of job</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.placeOfWork === 'Unemployed yet' 
                            ? 'No' 
                            : `${userData.placeOfWork}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Position</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.position === 'Unemployed yet' 
                            ? 'No' 
                            : `${userData.position}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Salary</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.salary === 'Unemployed yet' 
                            ? 'No' 
                            : `$${userData.salary}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Credit Score</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.creditScore === `Don't Have Credit Score` 
                            ? 'No'
                            : `${userData.creditScore}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Income Tax</div>
                        <div className='user-details-card-row-item-title'>
                            {userData.incomeTax === `Don't Have Income Tax` 
                            ? 'No'
                            : `$${userData.incomeTax}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Savings</div>
                        <div className='user-details-card-row-item-title'>${userData.savings}</div>
                    </div>
                </div>
                   
            </div>
            ))}
        </div>
    </div>

  )
}

export default BrokerUsers