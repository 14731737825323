import React, { useState } from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebaseConfig.ts';
import "./index.css"
import { Link, useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
  
    const handleLogin = async (e) => {
      e.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/profile')
      } catch (error) {
        setError(error.message);
      }
    };
    console.log(error);
  
  return (
      <form onSubmit={handleLogin} className="login-form">
      <h2>Login</h2>
          <input
            type="email"
            id="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        <button type="submit">Login</button>
        <Link to="/signup" className='signup-link' style={{ textDecoration: 'none', color: 'white', textAlign: 'center', marginTop: '30px' }}>Doesn't have an account? Click to signup.</Link>
      </form>
  )
}

export default Login