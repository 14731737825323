import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../firebaseConfig.ts'; // Adjust the import path as necessary
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp, getDocs, query, where } from 'firebase/firestore';
import { User, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './index.css'
import Header from '../../components/Header.tsx';
import HeaderMobile from '../../components/HeaderMobile.tsx';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import BrokerHeader from '../../components/BrokerHeader.tsx';
import BrokerHeaderMobile from '../../components/BrokerHeaderMobile.tsx';

interface BrokerData {
    id: string;
    email: string;
    fullname: string;
    phone: string;
    source: string;
    brokerStatus: string;
}

interface FormData {
  title: string;
  description: string;
  price: string;
  images: File[];
  bedroomAmount: string;
  documentChecking: string;
  floor: string;
  area: string;
  locationString: string;
  lat: number; // Updated type to number
  lng: number; // Updated type to number
}

const libraries: ("places")[] = ["places"];

function BrokerUpload() {
  const [brokerData, setBrokerData] = useState<BrokerData | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDiD15XJ8w3qW9C2KEkTQNx_qaeUF7-eDI", 
    libraries,
  });

  const autocompleteRef = useRef<google.maps.places.Autocomplete>();

  const onPlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry?.location) {
      // Use conditional chaining and provide fallbacks as necessary
      const location = place.geometry.location;
      setFormData(prev => ({
        ...prev,
        locationString: place.formatted_address || '',
        lat: location.lat(),
        lng: location.lng(),
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        locationString: '',
        lat: 0,
        lng: 0,
      }));
    }
  };



  const [user, setUser] = useState<User | null>(null);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    price: '',
    images: [],
    bedroomAmount: '',
    documentChecking: '',
    floor: '',
    area: '',
    locationString: "",
    lat: 0,
    lng: 0
  });
  const [files, setFiles] = useState<File[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const surveysCol = collection(db, "Surveys");
        const q = query(surveysCol, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userSurveyData = querySnapshot.docs[0].data();
          setBrokerData(userSurveyData as BrokerData);
        } else {
          console.log("No matching documents found!");
          setBrokerData(null);
        }
      } else {
        setBrokerData(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files));
    }
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const uploadPromises = files.map((file) => {
      const imageRef = ref(storage, `apartments_images/${file.name}`);
      return uploadBytes(imageRef, file).then((snapshot) => getDownloadURL(snapshot.ref));
    });
  
    try {
      const imageUrls = await Promise.all(uploadPromises);
      
      await addDoc(collection(db, "Apartments"), {
        ...formData,
        images: imageUrls,
        broker: brokerData?.fullname,
        brokerEmail: brokerData?.email,
        phone: brokerData?.phone,
        isActive: true,
        approved: false,
        created_at: serverTimestamp(),
      });
  
      navigate('/broker');
    } catch (error) {
      console.error("Error uploading apartment:", error);
    }
  };

  if (!user) {
    return <div>Access Denied</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>; // Show loading message or spinner until the script is loaded
  }

  if (brokerData && brokerData.brokerStatus === "Pending") {
    return <div className='error-pending'>Your account currently is on pending. Please wait until verified.</div>;
  }

  

  return (
    <div className='admin-container'>
      <BrokerHeader />
      <BrokerHeaderMobile />
      <form onSubmit={handleSubmit} className='signup-form'>
        <div className='admin-field'>
          <label>Price:</label>
          <input type="number" name="price" value={formData.price} onChange={handleChange} required />
        </div>
        <div className='admin-field'>
          <label>Bedroom Amount:</label>
          <select name="bedroomAmount" value={formData.bedroomAmount} onChange={handleChange} required>
            <option value="">Bedrooms</option>
            <option value="room">A room</option>
            <option value="studio">Studio</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div className='admin-field'>
          <label>Document Checking:</label>
          <select name="documentChecking" value={formData.documentChecking} onChange={handleChange} required>
            <option value="">Document Checking</option>
            <option value="No need">No need</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
        <div className='admin-field'>
          <label>Floor:</label>
          <select name="floor" value={formData.floor} onChange={handleChange} required>
            <option value="">Floor</option>
            <option value="1st">1st</option>
            <option value="2nd">2nd</option>
            <option value="3rd">3rd</option>
            <option value="Basement">Basement</option>
            <option value="Semi-basement">Semi-basement</option>
            <option value="Walk-in">Walk-in</option>
          </select>
        </div>
        <div className='admin-field'>
          <label>Address:</label>
          <Autocomplete className='admin-field'
            onLoad={(ref) => (autocompleteRef.current = ref)}
            onPlaceChanged={onPlaceChanged}
          >
            <input type="text" required />
          </Autocomplete>
        </div>
        <div className='admin-field'>
          <label>Area:</label>
          <select name="area" value={formData.area} onChange={handleChange} required>
            <option value="">Select area</option>
            {[
              'Bensonhurst', 'Sheepshead Bay', 'Homecrest', 'Midwood', 'Dyker Heights', 
              'Borough Park', 'Kensignton', 'Flatbush', 'Flatlands', 'Bergen Beach', 
              'Mill Basin', 'Sunset Park', 'Bay Ridge', 'Gravesend', 'Bath Beach', 
              'Manhattan', 'Queens', 'Staten Island'
            ].map((area) => (
              <option key={area} value={area}>{area}</option>
            ))}
          </select>
        </div>

        <div className='admin-field'>
          <label>Images:</label>
          <input id="upload-apartment-photos" type="file" name="images" onChange={handleFileChange} style={{ display: 'none' }} multiple />
        </div>
        <label htmlFor="upload-apartment-photos" className="button-upload-login">
            Upload photos
        </label>
        <div style={{ display: 'flex', gap: '10px' }}>
            {files.map((file, index) => (
            <img 
                key={index}
                src={URL.createObjectURL(file)}
                alt="Preview"
                style={{ width: '50px', height: '50px', objectFit: 'cover', border: '1px solid #262626' }}
            />
            ))}
        </div>
        <div className='admin-field'>
          <label>Additional details (optional)</label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <button type="submit">Upload Apartment</button>
      </form>
    </div>
  );
}

export default BrokerUpload;