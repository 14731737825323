import React from 'react'
import './Policy.css'
import HeaderMobile from '../../components/HeaderMobile.tsx'
import Header from '../../components/Header.tsx'

function Terms() {
  return (
    <div className='policy-container'>
        <Header />
        <HeaderMobile />
        <div className='policy-title'>Website Terms and Conditions for EasyRent</div>

        <div className='policy-column'>
            <div className='policy-title'>Acceptance of Terms</div>
            <div className='policy-text'>By accessing and using the EasyRent website, you accept and agree to be bound by the terms and provision of this agreement, including any specific conditions outlined below. In addition, when using EasyRent's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Advice</div>
            <div className='policy-text'>The contents of the EasyRent website do not constitute advice and should not be relied upon in making or refraining from making, any decision.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Change of Use</div>
            <div className='policy-text'>EasyRent reserves the right to:
            - Change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm that EasyRent shall not be liable to you for any such change or removal.
            - Change these Terms and Conditions at any time, and your continued use of the Website following any changes shall be deemed to be your acceptance of such change.
            </div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Links to Third Party Websites</div>
            <div className='policy-text'>The EasyRent website may include links to third-party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that EasyRent is not responsible for the content or availability of any such sites.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Copyright</div>
            <div className='policy-text'>The Intellectual Property Rights in the EasyRent website and the materials on or accessible via it belong to EasyRent or its licensors. This website and the materials on or accessible via it and the Intellectual Property Rights therein may not be copied, distributed, published, licensed, used, or reproduced in any way (save to the extent strictly necessary for, and for the purposes of, accessing and using this website).</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Security and Data Use</div>
            <div className='policy-text'>The EasyRent platform may experience unauthorized access by third parties, leading to potential exposure or leakage of user information, including but not limited to login credentials, application data, messages, cookies, and other related usage information. EasyRent disclaims all responsibility for such breaches and their consequences. By using this platform, you agree to the use of your data to enhance your navigation experience.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Technical Issues and Service Improvement</div>
            <div className='policy-text'>You acknowledge that the EasyRent platform may experience technical issues, including malfunctioning or absent functions and buttons. EasyRent is committed to continuously improving our service, and any imperfections will be addressed in due course. By continuing to use our platform, you agree to tolerate such inconveniences temporarily.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Subscription Service and Refund Policy</div>
            <div className='policy-text'>Users who are not satisfied with their purchase should note that refunds are not guaranteed. By subscribing to EasyRent's service, you agree to this no-refund policy.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>No Guarantee of Finding an Apartment</div>
            <div className='policy-text'>EasyRent does not guarantee that users will successfully find an apartment to move into. If users are unable to find housing, regardless of the time spent searching, EasyRent bears no responsibility for such outcomes.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Disclaimer of Responsibility for Housing Conditions</div>
            <div className='policy-text'>EasyRent is not responsible for the conditions of any housing, the content of any lease agreements, or any events that occur after a lease agreement is signed or an appointment is made through the platform. Users are advised to conduct their due diligence before entering into any agreements.</div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Disclaimers and Limitation of Liability</div>
            <div className='policy-text'>The EasyRent website is provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, and accuracy.
            To the extent permitted by law, EasyRent will not be liable for any indirect or consequential loss or damage whatsoever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the website.
            </div>
        </div>

        <div className='policy-column'>
            <div className='policy-title'>Indemnity</div>
            <div className='policy-text'>You agree to indemnify and hold EasyRent and its employees and agents harmless from and against all liabilities, legal fees, damages, losses, costs, and other expenses in relation to any claims or actions brought against EasyRent arising out of any breach by you of these Terms and Conditions or other liabilities arising out of your use of this website.</div>
        </div>
    </div>
  )
}

export default Terms