import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, Timestamp, limit } from 'firebase/firestore';
import { db, auth } from '../../firebaseConfig.ts';
import { onAuthStateChanged } from 'firebase/auth';
import './index.css'
import Header from '../../components/Header.tsx';
import docsend from '../../assets/docsend.png'
import imagesend from '../../assets/imagesend.png'
import phone from '../../assets/phone.png'
import arrow from '../../assets/arrow.png'
import searchicon from '../../assets/searchicon.png'
import { Link, useNavigate } from 'react-router-dom';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import chat from '../../assets/chat.png';
import { useLocation } from 'react-router-dom';

interface UserWithLastMessage extends UserSurveyData {
  lastMessage: string;
  lastMessageTimestamp: Timestamp;
}

interface Message {
  id: string;
  from: string;
  to: string;
  message: string;
  timestamp: Timestamp;
  unread: boolean;
  type: 'text' | 'photo' | 'document' | 'apartment';
  fileUrl?: string;
  apartmentDetailsPhoto;
  apartmentDetailsLocation;
  apartmentDetailsBedroom;
  apartmentDetailsPrice;
}

interface UserSurveyData {
  uid: string;
  fullname: string;
  pfp: string;
  phone: string;
}

interface ApartmentInfo {
  photo: string;
  bedrooms: string;
  locationString: string;
  link: string;
  price: string;
}


const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const adminUid = 'r5XE4OKvtWamTgoLkBu33badbQy2';
  const [currentUserUid, setCurrentUserUid] = useState<string | null>(null);
  const [userSurveyData, setUserSurveyData] = useState<UserSurveyData | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [lastMessage, setLastMessage] = useState<Message | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [apartmentInfo, setApartmentInfo] = useState<ApartmentInfo | null>(null);

  const location = useLocation();
  const navigate = useNavigate()

  
useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const initialMessage = queryParams.get('message');
  const photo = queryParams.get('photo');
  const bedrooms = queryParams.get('bedrooms');
  const price = queryParams.get('price');
  const locationString = queryParams.get('locationString') || '';
  const link = queryParams.get('link');

  if (initialMessage) {
    setNewMessage(decodeURIComponent(initialMessage));
  }
  if (photo && bedrooms && price && location && link) {
    setApartmentInfo({
      photo: photo,
      bedrooms: decodeURIComponent(bedrooms),
      price: decodeURIComponent(price),
      locationString: decodeURIComponent(locationString),
      link: decodeURIComponent(link)
    });
  }
}, [location]);

  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid);
      } else {
        setCurrentUserUid(null);
      }
    });
  
    return () => unsubscribe(); // Cleanup on unmount
  }, []);
  


  useEffect(() => {
    const q = query(collection(db, 'Surveys'), where('uid', '==', adminUid)); // Adjust as needed
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Assuming there's only one document per user
        setUserSurveyData(doc.data() as UserSurveyData); // Set user data
      });
    });
  }, []);

  // Subscribing to messages
  useEffect(() => {
    if (currentUserUid) { // Ensure currentUserUid is not null
      const msgsQuery = query(collection(db, 'Messages'), where('from', 'in', [currentUserUid, adminUid]), where('to', 'in', [currentUserUid, adminUid]), orderBy('timestamp'));
      const unsubscribe = onSnapshot(msgsQuery, (querySnapshot) => {
        const msgs: Message[] = [];
        querySnapshot.forEach((doc) => {
          msgs.push({ id: doc.id, ...doc.data() } as Message);
        });
        setMessages(msgs);
      });
  
      return () => unsubscribe(); // Cleanup subscription
    }
  }, [currentUserUid]);

  useEffect(() => {
    if (currentUserUid) {
      // Adjust the query to fetch the last message
      const lastMessageQuery = query(collection(db, 'Messages'), 
      where('from', 'in', [currentUserUid, adminUid]), 
      where('to', 'in', [currentUserUid, adminUid]),
        orderBy('timestamp', 'desc'), 
        limit(1)
      );
  
      const unsubscribe = onSnapshot(lastMessageQuery, (querySnapshot) => {
        let lastMessageData: Message | null = null;
        querySnapshot.forEach((doc) => {
          // Since the query is limited to 1, this will run once with the last message
          lastMessageData = { id: doc.id, ...doc.data() } as Message;
        });
  
        if (lastMessageData) {
          setLastMessage(lastMessageData);
        }
      });
  
      return () => unsubscribe();
    }
  }, [currentUserUid]);

  const formatDateToTimeString = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // Format hours and minutes to always be two digits
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };
  
  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
  
    messages.forEach((msg) => {
      const date = msg.timestamp.toDate();
      const dateKey = date.toISOString().split('T')[0];
  
      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
  
      groupedMessages[dateKey].push(msg);
    });
  
    return groupedMessages;
  };

  const getLabelForDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    const dateObj = new Date(date);
    const isToday = dateObj.toISOString().split('T')[0] === today.toISOString().split('T')[0];
    const isYesterday = dateObj.toISOString().split('T')[0] === yesterday.toISOString().split('T')[0];
  
    if (isToday) return 'Today';
    if (isYesterday) return 'Yesterday';
    
    return dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);
  

  const uploadFileToStorage = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${file.name}_${Date.now()}`);
  
    try {
      // Upload file to the reference path in Firebase Storage
      const uploadTaskSnapshot = await uploadBytesResumable(storageRef, file);
      // Get the download URL after successful upload
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Upload failed", error);
      throw new Error("Failed to upload file.");
    }
  };
  
  const determineMessageType = (file) => {
    const fileType = file.type;
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const validDocumentTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more MIME types as needed
  
    if (!apartmentInfo && validImageTypes.includes(fileType)) {
      return 'photo';
    } else if (!apartmentInfo && validDocumentTypes.includes(fileType)) {
      return 'document';
    } else if (apartmentInfo){
      return 'apartment';
    } else {
      return 'other'
    }
  };
  
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setUploadedFile(file);
    }
  };
  
    
  const handleSendMessage = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();  // This will handle both mouse and keyboard events
    
    if (!newMessage.trim() && !uploadedFile) return;
  
    let messageType = 'text'; // Default message type
    let messageContent = newMessage; // The text content of the message
    let fileUrl: string | null = null;
    let apartmentDetailsBedroom: string | null = null;
    let apartmentDetailsPrice: string | null = null;
    let apartmentDetailsLink: string | null = null;
    let apartmentDetailsLocation: string | null = null;


    console.log('SENDING 1')
    
  
    // Check if there's an uploaded file
    if (!apartmentInfo && uploadedFile) {
      try {
        // Upload the file and get the URL
        setNewMessage('')
        fileUrl = await uploadFileToStorage(uploadedFile);
        // Determine the message type based on the file
        messageType = determineMessageType(uploadedFile);
        // Use the file name as the message content or any placeholder text
        messageContent = uploadedFile.name; // Or any placeholder text you want to show in the message
      } catch (error) {
        console.error("Error uploading file: ", error);
        return; // Exit the function if the file upload fails
      } finally {
        // Regardless of success or failure, reset the uploadedFile state
        setUploadedFile(null);
      }
    }
    
    if (apartmentInfo && !uploadedFile) {
      try {
        // Upload the file and get the URL
        fileUrl = apartmentInfo.photo;
        // Determine the message type based on the file
        messageType = "apartment";
        // Use the file name as the message content or any placeholder text
        messageContent = newMessage;
        apartmentDetailsBedroom = apartmentInfo.bedrooms;
        apartmentDetailsLocation = apartmentInfo.locationString;
        apartmentDetailsPrice = apartmentInfo.price;
        apartmentDetailsLink = apartmentInfo.link;
         // Or any placeholder text you want to show in the message
         console.log('SENDING AP')
         navigate('/chat/manager')
         console.log(apartmentDetailsLink, apartmentDetailsPrice, apartmentDetailsLocation, apartmentDetailsBedroom, messageContent, messageType)
      } catch (error) {
        console.error("Error uploading file: ", error);
        return; // Exit the function if the file upload fails
      } 
    }
  
    // Proceed with resetting the message input and sending the message only if no file upload is attempted or if it succeeds
    setNewMessage('');
  
    // Add the message details to Firestore
    await addDoc(collection(db, 'Messages'), {
      from: currentUserUid,
      to: adminUid,
      message: messageContent,
      timestamp: Timestamp.now(),
      unread: true,
      type: messageType,
      fileUrl: fileUrl,
      apartmentDetailsBedroom,
      apartmentDetailsLocation,
      apartmentDetailsPrice,
      apartmentDetailsLink
    });
    setApartmentInfo(null);
    console.log('DONE')
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <div className='chat-container'>
      <div className='header-chat'>
    <Header />
    </div>
    <div className='chat'>

      <div className='sidebar-users'>

        <input type="text" placeholder="Search" className='search-input' style={{padding: '14px 14px 14px 50px', backgroundColor: '#242424', borderRadius: '8px', color: '#999999', fontSize: '16px', margin: '20px 20px 20px 20px', border: 'none'}}/>
        <img alt='Search Icon' src={searchicon} style={{width: '20px', height: '20px', objectFit: 'cover', position: 'absolute', marginTop: '32px', marginLeft: '36px'}} />

      {userSurveyData && lastMessage ? (
        <div className='chat-sidebar-user'>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src={userSurveyData.pfp}/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>{userSurveyData.fullname}</div>
            <div className='chat-one-text'>{lastMessage.message}</div>
          </div>
          <div className='chat-sidebar-date'>
                {lastMessage.timestamp.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}
          </div>
        </div>
      ) : (
        <div className='chat-sidebar-user'>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src='https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultpfp.png?alt=media&token=0cd3905f-ff0a-4f82-b771-97fe71583c87'/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>Loading..</div>
            <div className='chat-one-text'>Loading..</div>
          </div>
        </div>
      )}
      </div>

      <div className='chat-messages'>
      {userSurveyData ? (
        <div className='chat-one-user'>
          <Link to="/" className='arrow-chat-icon'><img src={arrow} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/></Link>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src={userSurveyData.pfp}/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>{userSurveyData.fullname}</div>
            <div className='chat-one-text'>Personal Manager</div>
          </div>
          <a href={`tel:${userSurveyData.phone}`} className='phone-button'>
            <img src={phone} className='phone-button-icon'/>
          </a>
        </div>
      ) : (
        <div className='chat-one-user'>
          <Link to="/" className='arrow-chat-icon'><img src={arrow} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/></Link>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src='https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultpfp.png?alt=media&token=0cd3905f-ff0a-4f82-b771-97fe71583c87'/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>Loading..</div>
            <div className='chat-one-text'>Personal Manager</div>
          </div>
        </div>
      )}

<div className='messages'>
        {Object.keys(groupedMessages).map((date) => (
          <div className='groupped-messages' key={date}>
            <div className="date-label">{getLabelForDate(date)}</div>
            {groupedMessages[date].map((msg) => (
              <div key={msg.id} className={`message ${msg.type === 'photo' ? (msg.from === currentUserUid ? 'message-photo-sent' : 'message-photo-received') : (msg.from === currentUserUid ? 'message-sent' : 'message-received')}`}>
                {msg.type === 'text' && (
                  <div className='message-text'>{msg.message}</div>
                )}
                {msg.type === 'photo' && (
                  <img src={msg.fileUrl} alt="Photo" className="message-photo" />
                )}
                {msg.type === 'document' && (
                  <a href={msg.fileUrl} target="_blank" className='document-message'>
                  <div className="media-sector-media-pdf">
                      pdf
                  </div>
                  
                  <div className='media-sector-column'>
                  <div className='document-message-name'>{msg.message}</div>
                  <div className='document-message-click'>Click to open</div>
                  </div>
                  </a>
                )}
                {msg.type === 'apartment' && (
                  <><Link to={msg.apartmentDetailsLink} className='document-message'>
                    <img src={msg.fileUrl} alt="Photo" className="message-apartment-photo" />

                    <div className='media-sector-column'>
                      <div className='document-message-name'>{msg.apartmentDetailsBedroom}, {msg.apartmentDetailsPrice}</div>
                      <div className='document-message-click'>{msg.apartmentDetailsLocation}</div>
                    </div>


                  </Link><div className='message-text' style={{marginTop: '10px'}}>{msg.message}</div></>
                )}

                <div className={(msg.type === "photo" ? 'message-photo-date' : 'message-date')}>
                  {formatDateToTimeString(msg.timestamp.toDate())}
                </div>
              </div>
            ))}
          </div>
        ))}
         <div ref={messagesEndRef} /> 
        </div>

        {apartmentInfo && (
        <div className='media-sector'>
            <img src={apartmentInfo.photo} className='media-sector-media' alt="Apartment" />
            <Link to={apartmentInfo.link} className='media-sector-column'>
              <div className='media-sector-column-text'>{apartmentInfo.bedrooms} - ${apartmentInfo.price}/month</div>
              <div className='media-sector-column-name'>{apartmentInfo.locationString}</div>
            </Link>
        </div>
        )}

        {uploadedFile && (
        <div className='media-sector'>

          {uploadedFile && (
            determineMessageType(uploadedFile) === 'photo' ? (
              <>
              <img src={URL.createObjectURL(uploadedFile)} className='media-sector-media' alt="Uploaded Preview" />
              <div className='media-sector-column'>
                <div className='media-sector-column-text'>Sending photo</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
              </>
            ) : (
              <>
              <a href={URL.createObjectURL(uploadedFile)} download={uploadedFile.name} className='media-sector-media-pdf'>
                pdf
              </a>
              <div className='media-sector-column'>

              <div className='media-sector-column-text'>Sending document</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
                      </>
            )
          )}
        </div>
        )}


        <div className='messages-type'>
      <div className='icons-row'>
      <input
        id="upload-photo"
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
      <input
        id="upload-doc"
        type="file"
        accept=".pdf,.docx"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />

        <label htmlFor='upload-photo'><img alt='Chat Icon' src={imagesend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
        <label htmlFor='upload-doc'><img alt='Chat Icon' src={docsend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
      </div>
      <input
        type="text"
        value={newMessage}
        placeholder='Type a message'
        onChange={(e) => setNewMessage(e.target.value)}
        className='message-input'
      />
      <button 
        onClick={handleSendMessage}
        className='send-button'>
        <img src={chat} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/>
      </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ChatPage;
