import React from 'react'
import logo from '../assets/easyrentallogo.png'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>

        <div className='footer-row'>
            <div className='footer-text'>@2023 EasyRent. All Rights Reserved.</div>
            <Link to='/policy/terms-and-conditions' className='footer-text'>Terms & Conditions</Link>
        </div>
    </div>
  )
}

export default Footer