import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig.ts'; // Ensure this path is correct
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import Header from '../../components/Header.tsx';
import Filter from '../../components/Filter.tsx';
import AptCard from '../../components/AptCard.tsx';
import './index.css';
import HeaderMobile from '../../components/HeaderMobile.tsx';
import LoadingAptCard from '../../components/LoadingAptCard.tsx';

interface Apartment {
  id: string;
  title: string;
  description: string;
  price: string;
  images: string[];
  bedroomAmount: string;
  documentChecking: string;
  floor: string;
  locationString: string;
  isActive: boolean;
}

function Apartments() {
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [filteredApartments, setFilteredApartments] = useState<Apartment[]>([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const fetchApartments = async () => {
      setIsLoading(true);
      const apartmentsRef = collection(db, "Apartments");
 
      const q = query(apartmentsRef, where("isActive", "==", true), where("approved", "==", true), orderBy("created_at", "desc"));
      const querySnapshot = await getDocs(q);
      
      const apartmentsArray: Apartment[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as Omit<Apartment, 'id'>
      }));
  
      setApartments(apartmentsArray);
      setFilteredApartments(apartmentsArray);
      setIsLoading(false);
    };
  
    fetchApartments();
  }, []);

  const handleFilterChange = (filters) => {
    let filteredData = apartments.filter((apartment) => {
      const matchesBedroomAmount = filters.bedroomAmount ? apartment.bedroomAmount === filters.bedroomAmount : true;
      const matchesDocumentChecking = filters.documentChecking ? apartment.documentChecking === filters.documentChecking : true;
      const matchesFloor = filters.floor ? apartment.floor === filters.floor : true;

      let matchesPriceRange = true;
      if (filters.price) {
        const [minPrice, maxPrice] = filters.price.split('-').map(Number);
        const price = Number(apartment.price);
        matchesPriceRange = price >= minPrice && (maxPrice ? price <= maxPrice : true);
      }

      return matchesBedroomAmount && matchesDocumentChecking && matchesFloor && matchesPriceRange;
    });

    setFilteredApartments(filteredData);
  };

  return (
    <div className='apartments-container'>
      <Header />
      <HeaderMobile />
      <div className='apartments-first'>
        <div className='apartments-title'>Discover All Apartments</div>
        <div className='apartments-text'>Here you can find all available apartments in New York with easy document verification.</div>
      </div>
      <Filter onFilterChange={handleFilterChange}/>
      <div className='home-listings'>
      {isLoading ? ( 
        <><LoadingAptCard /><LoadingAptCard /><LoadingAptCard /></>
      ) : (
        filteredApartments.map(apartment => (
          <AptCard key={apartment.id} apartment={apartment} />
        ))
      )}
      </div>
    </div>
  );
}

export default Apartments;
