import React, { useState } from 'react';
import './Filter.css'; 

function Filter({ onFilterChange }) {
    const [filters, setFilters] = useState({
        bedroomAmount: '',
        documentChecking: '',
        floor: '',
        price: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

      const applyFilters = () => {
        onFilterChange(filters);
      };
      
  return (
    <div className="filter-container">
      <select className="filter-dropdown" name='bedroomAmount' onChange={handleChange}>
        <option value="">Bedrooms</option>
        <option value="room">A room</option>
        <option value="studio">Studio</option>
        <option value="1">1bdr</option>
        <option value="2">2bdr</option>
        <option value="3">3bdr</option>
        <option value="4">4+bdr</option>
      </select>

      <select className="filter-dropdown" name='documentChecking' onChange={handleChange}>
        <option value="">Document Checking</option>
        <option value="No need">No need</option>
        <option value="Low">Low</option>
        <option value="Medium">Medium</option>
        <option value="High">High</option>
      </select>

      <select className="filter-dropdown" name='floor' onChange={handleChange}>
        <option value="">Floor</option>
        <option value="1st">1st</option>
        <option value="2nd">2nd</option>
        <option value="3rd">3rd</option>
        <option value="basement">Basement</option>
        <option value="semi-basement">Semi-basement</option>
        <option value="walk-in">Walk-in</option>
      </select>

      <select className="filter-dropdown" name='price' onChange={handleChange}>
        <option value="">Price Range</option>
        <option value="0-1000">$0-$1000</option>
        <option value="1000-2000">$1000-$2000</option>
        <option value="2000-3000">$2000-$3000</option>
        <option value="3000+">$3000+</option>
      </select>

      <button className="button-primary" onClick={applyFilters}>Search by Filters</button>
    </div>
  );
}

export default Filter;


