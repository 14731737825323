import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebaseConfig.ts'; // Adjust the path as needed
import { onAuthStateChanged, User } from 'firebase/auth';
import { onSnapshot, collection, addDoc, getDoc, doc, query, getDocs, where } from 'firebase/firestore';
import Header from '../../components/Header.tsx';
import './index.css'
import pricing from '../../assets/pricing-icon.png'
import HeaderMobile from '../../components/HeaderMobile.tsx';
import feedback1 from '../../assets/feedback1.png'
import feedback2 from '../../assets/feedback2.png'
import feedback3 from '../../assets/feedback3.png'
import feedback4 from '../../assets/feedback4.png'
import feedback5 from '../../assets/feedback5.png'
import feedback6 from '../../assets/feedback6.png'
import Footer from '../../components/Footer.tsx';


function Plans() {
  // Explicitly declare the type of state as User | null
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // This callback now correctly handles the User | null type
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user); // user is User | null, matching the state type
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const fetchUserPromoCode = async (userId) => {
    const surveysCol = collection(db, "Surveys");
    const q = query(surveysCol, where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    let promoCode = null;
  
    querySnapshot.forEach((doc) => {
      promoCode = doc.data().promoCode;
    });
  
    return promoCode;
  };

  const handleUpgradeClick = async () => {
    if (!currentUser) {
      alert("You must be logged in to upgrade.");
      return;
    }
    setIsLoading(true);
    try {
      const promoCode = await fetchUserPromoCode(currentUser.uid); 
      console.log(promoCode)
      const docRef = await addDoc(collection(db, 'customers', currentUser.uid, 'checkout_sessions'), {
        price: 'price_1OzOioKM4P2bsFn9MUzCfJRf',
        success_url: window.location.origin + '/profile',
        cancel_url: window.location.origin + '/profile',
        allow_promotion_codes: true,
        ...(promoCode ? {promotion_code: promoCode} : {}),
      });
  
      onSnapshot(docRef, (snap) => {
        const data = snap.data() as { error?: { message: string }, url?: string };
        if (data.error) {
          alert(`An error occurred: ${data.error.message}`);
        }
        if (data.url) {
          window.location.assign(data.url);
        }
      });
    } catch (error) {
      console.error("Failed to initiate Stripe checkout session:", error);
      setIsLoading(false);
    }
  };
  

  return (
    <div className='pricing-container'>
        <Header />
        <HeaderMobile />

    <div className='pricing-first'> 
    <div className='price-left'>
        <img src={pricing} alt='Pricing-icon' style={{width: '70px', objectFit: 'cover'}} />
        <div className='price-title'>Find Your Dream Housing In Less Than 2 Weeks</div>
        <div className='price-subtitle'>Tired of looking for an apartment? Don’t worry, starting from now you are in good hands.</div>
    </div>
    <div className='popup-container'>
        <div className='popup-pricing'>
            <div className='popup-pricing-column'>
                <div className='popup-pricing-title'>EasyRent Premium</div>
                <div className='popup-pricing-price-column'>
                    <div className='popup-pricing-adv'>Apartments with low check</div>
                    <div className='popup-pricing-adv'>Fast replies from brokers</div>
                    <div className='popup-pricing-adv'>Helping with documents</div>
                    <div className='popup-pricing-adv'>Quick move-in</div>
                </div>
                <div className='popup-pricing-price-column'>
                    <div className='popup-pricing-price'>$20/week</div>
                    <div className='popup-pricing-price-text'>Pause or cancel anytime</div>
                </div>
            </div>
            { isLoading ? (<button className='button-loading'>Redirecting..</button>) : 
            (<button onClick={handleUpgradeClick} className='button-popup-pricing'>
            Purchase Subscription
            </button>)}
        </div>
    </div>
    </div>

    <div className='pricing-grid'>
      <div className='pricing-grid-card'>
        <div className='pricing-grid-title'>8/10</div>
        <div className='pricing-grid-text'>Customers find houses</div>
      </div>

      <div className='pricing-grid-card'>
        <div className='pricing-grid-title'>4 days</div>
        <div className='pricing-grid-text'>Average moving time</div>
      </div>

      <div className='pricing-grid-card'>
        <div className='pricing-grid-title'>7 min</div>
        <div className='pricing-grid-text'>Average respond time</div>
      </div>

      <div className='pricing-grid-card'>
        <div className='pricing-grid-title'>348</div>
        <div className='pricing-grid-text'>Clients found home</div>
      </div>
    </div>

    <div className='pricing-comparison'>
        <div className='pricing-comparison-card'>
            <div className='pricing-comparison-card-title'>Average way to find apartment</div>
            <div className='pricing-comparison-card-text'>Spending 2-6 months searching</div>
            <div className='pricing-comparison-card-text'>Getting declined because of documents</div>
            <div className='pricing-comparison-card-text'>Brokers do not contact</div>
            <div className='pricing-comparison-card-text'>Landords are too rude and agressive</div>
        </div>

        <div className='pricing-comparison-card'>
            <div className='pricing-comparison-card-title-pro'>Find apartment with EasyRental</div>
            <div className='pricing-comparison-card-text'>Search convinient options on platform</div>
            <div className='pricing-comparison-card-text'>Apply to apartment by 1 click</div>
            <div className='pricing-comparison-card-text'>Get broker's response in a day</div>
            <div className='pricing-comparison-card-text'>Go to viewing, sign a lease and move-in</div>
        </div>
    </div>

    <div className='pricing-feedbacks'>
        <div className='pricing-feedbacks-text-title'>
            <div className='pricing-feedbacks-title'>What Our Clients Say</div>
            <div className='pricing-feedbacks-text'>9/10 of our clients find their dream apartments less than a week. Read their stories of success and become one of them.</div>
        </div>

        <div className='pricing-feedbacks-grid'>
              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback1} />
                    <div className='pricing-feedbacks-profile-title'>Ruslan Khasanov</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— Искали квартиру 3 месяца, везде - отказ. То документы не подходят, то просто не нравимся. Здесь нашли идеальный вариант за 4 дня.</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 4 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>


              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback4} />
                    <div className='pricing-feedbacks-profile-title'>Murad Akhmedov</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— Работаю тракистом, особо времени нет на поиски. А тут достаточно одного клика. Сэкономили кучу времени. Всем рекомендую.</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 6 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>


              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback3} />
                    <div className='pricing-feedbacks-profile-title'>Aleksandr Bulkich</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— Давно не хватало такого сервиса. За свои деньги - топ. Устал уже звонить брокерам, постоянно не на связи. А тут получаешь ответ молниеносно.</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 3 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>



              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback5} />
                    <div className='pricing-feedbacks-profile-title'>Georgii Meshveliani</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— Thank you guys. I’ve been exhausted of sending our personal info & docs to the brokers every time. Moreover, they don’t even reply. And you’ve just made this process so much easier. Truly thank you.</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 10 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>



              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback6} />
                    <div className='pricing-feedbacks-profile-title'>Dilnoza Umarova</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— Подали на 30 квартир меньше чем за час, квартиру нашли за 5 дней. Успели как раз к переезду. Спасибо!</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 5 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>



              <div className='pricing-feedbacks-card'>
                  <div className='pricing-feedbacks-profile-row'>
                    <img className='pricing-feedbacks-profile-media' src={feedback2} />
                    <div className='pricing-feedbacks-profile-title'>Svetlana Stadnichuk</div>
                  </div>

                  <div className='pricing-feedbacks-card-text'>— До этого использовала телеграм и фейсбук для поиска, приходилось ориентироваться среди десятки чатов и каналов. А тут все в одном месте - очень удобно!</div>

                  <div className='pricing-feedbacks-footer-row'>
                    <div className='pricing-feedbacks-card-moving-text'>Moving time: 12 days</div>
                    <div className='pricing-feedbacks-card-area-text'>Area: Brooklyn, New York</div>
                  </div>
              </div>
        </div>
    </div>

    <div className='pricing-action'>
      <div className='pricing-action-column'>
         <div className='pricing-action-title'>Easyrent Premium will help you to find an apartment in less than 2 weeks</div>
         <div className='pricing-action-text'>Start your home search with EasyRent Premium. It's quick, easy, and you're just one click away from finding the perfect place. Let EasyRent guide you home.</div>
      </div>

      { isLoading ? (<button className='button-loading'>Redirecting..</button>) : 
            (<button onClick={handleUpgradeClick} className='button-popup-pricing'>
            Purchase Subscription
      </button>)}
    </div>

    <Footer />

    </div>
  );
}

export default Plans;
