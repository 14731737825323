import React, { useState, useEffect } from 'react';
import "./AdminHeader.css";
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useLocation
import logo from '../assets/easyrentallogo.png';
import { auth } from '../firebaseConfig.ts';
import chat from '../assets/chat.png';
import { signOut } from 'firebase/auth';

interface User {
  email?: string | null;
}

function AdminHeader() {
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      console.log(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Helper function to determine if a path is the current location
  const isActive = (path: string) => location.pathname === path;


  return (
    <div className='header'>
      <div className='header-container'>
        <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
        <div className='header-menu'>
          <Link to="/admin" className={isActive('/admin') ? 'header-menu-item active' : 'header-menu-item'} style={{ textDecoration: 'none', color: 'white' }}>Dashboard</Link>
          <Link to="/admin-upload" className={isActive('/admin-upload') ? 'header-menu-item active' : 'header-menu-item'} style={{ textDecoration: 'none', color: 'white' }}>Upload</Link>
        </div>
        <Link to="/admin-chat/@" style={{ backgroundColor: '#141414', borderRadius: '10px', border: '1px solid #262626', padding: '12px 20px', textDecoration: 'none', color: 'white', gap: '7px', alignItems: 'center', display: 'flex' }}><img src={chat} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/></Link>
      </div>
    </div>
  );
}

export default AdminHeader;
