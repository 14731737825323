import React from 'react';
import './AptCard.css';
import { useNavigate } from 'react-router-dom';

function AptCard({ apartment }) {
  const navigate = useNavigate();

  // A utility function to format the bedroom amount text
  const formatBedroomAmount = (bedroomAmount) => {
    if (bedroomAmount === 'studio') {
      return 'Studio';
    } else if (bedroomAmount === 'room') {
      return 'A room';
    } else {
      return `${bedroomAmount} bedroom`;
    }
  };

  const formatFloor = (floor) => {
    if (floor === 'Walk-in') {
      return 'Walk-in';
    } else if (floor === 'Semi-basement') {
      return 'Semi-basement';
    } else if (floor === 'Basement') {
      return 'Basement';
    } else {
      return `${floor} floor`;
    }
  };

  const imageUrl = apartment.images && apartment.images.length > 0 ? apartment.images[0] : 'https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultphoto.png?alt=media&token=c5f529c8-af52-429e-9ce2-290f9910d31c';

  const handleViewDetailsClick = () => {
    navigate(`/apartment/${apartment.id}`);
  };

  return (
    <div className="apartment-card">
      <img className='apartment-media' src={imageUrl} alt='Apartment media' loading='lazy' />
      <div className='apartment-info'>
        <div className='apartment-info-top'>
          <div className='apartment-title'>{formatBedroomAmount(apartment.bedroomAmount)} for rent</div>
          <div className='apartment-description'>{apartment.locationString}</div>
        </div>
        <div className='apartment-description'>{apartment.description}</div>
        <div className='filter-tags'>
          {/* Use the formatBedroomAmount function to display the bedroom amount correctly */}
          <span className='filter-tag'>{formatBedroomAmount(apartment.bedroomAmount)}</span>
          <span className='filter-tag'>{apartment.documentChecking}</span>
          <span className='filter-tag'>{formatFloor(apartment.floor)}</span>
        </div>
        <div className='card-bottom'>
          <div className='price-container'>
            <div className='apartment-price-tag'>Price</div>
            <div className='apartment-price'><strong>${apartment.price}</strong> monthly</div>
          </div>
          <button className='button-active' onClick={handleViewDetailsClick}>View Details</button>
        </div>
      </div>
    </div>
  );
}

export default AptCard;
