import React from 'react';
import './LoadingAptCard.css'; 


function LoadingAptCard() {

  return (
    <div className="loading-apartment-card">
      <div className='loading-apartment-media'></div>
      <div className='loading-apartment-info'>
        <div className='loading-apartment-info-top'>
          <div className='loading-apartment-title'></div>
          <div className='loading-apartment-description'></div>
        </div>
        <div className='loading-apartment-description'></div>
        <div className='loading-filter-tags'>
          <span className='loading-filter-tag'></span>
          <span className='loading-filter-tag'></span>
          <span className='loading-filter-tag'></span>
        </div>
        <div className='loading-card-bottom'>
        <div className='loading-price-container'>
        <div className='loading-apartment-price-tag'></div>
        <div className='loading-apartment-price'></div>
        </div>
        {/* <button className='button-active'>Loading..</button> */}
        </div>
      </div>
    </div>
  );
}

export default LoadingAptCard;
