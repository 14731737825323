import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { query, collection, where, getDocs } from "firebase/firestore";
import { auth, db } from '../firebaseConfig.ts';

const IfBrokerToPanel = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfBroker = async (uid) => {
      const surveysRef = collection(db, "Surveys");
      const q = query(surveysRef, where("uid", "==", uid), where("role", "==", "broker"));
      const querySnapshot = await getDocs(q);
      
      // If there's at least one document, it means the user is a broker
      if (!querySnapshot.empty) {
        navigate('/broker'); // Navigate to the broker panel
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkIfBroker(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return children;
};

export default IfBrokerToPanel;
