import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig.ts';

const adminUIDs = ['r5XE4OKvtWamTgoLkBu33badbQy2'];

const RequireAdmin = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && adminUIDs.includes(user.uid)) {
        setIsAdmin(true);
      } else {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return isAdmin ? children : null;
};

export default RequireAdmin;
