import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ApartmentPage from "./routes/ApartmentPage/index.tsx";
import Home from "./routes/Home/index.tsx";
import Apartments from "./routes/Apartments/index.tsx";
import SignUp from "./routes/SignUp/index.tsx";
import Profile from "./routes/Profile/index.tsx";
import Login from "./routes/Login/index.tsx";
import AdminDashboard from "./routes/AdminDashboard/index.tsx";
import AdminUpload from "./routes/AdminUpload/index.tsx";
import AdminEdit from "./routes/AdminEdit/index.tsx";
import Plans from "./routes/Plans/index.tsx";
import RequireAuth from './components/RequireAuth.tsx';
import RedirectIfAuth from "./components/RedirectIfAuth.tsx";
import RequireAdmin from "./components/RequireAdmin.tsx";
import AdminUsers from "./routes/AdminUsers/index.tsx";
import Chat from "./routes/Chat/index.tsx";
import Broker from "./routes/Broker/index.tsx";
import AdminChat from "./routes/AdminChat/index.tsx";
import IfAdminToPanel from "./components/IfAdminToPanel.tsx";
import AdminBrokers from "./routes/AdminBrokers/index.tsx";
import IfBrokerToPanel from "./components/IfBrokerToPanel.tsx";
import BrokerPanel from "./routes/BrokerPanel/index.tsx";
import RequireBroker from "./components/RequireBroker.tsx";
import BrokerUpload from "./routes/BrokerUpload/index.tsx";
import BrokerEdit from "./routes/BrokerEdit/index.tsx";
import Terms from "./routes/Policies/Terms.tsx";
import BrokerUsers from "./routes/BrokerUsers/index.tsx";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Apartments />} />
      <Route path="/apartment/:id" element={<ApartmentPage />} />
      <Route path="/apartments" element={<Apartments />} />
      <Route path="/pricing" element={<Plans />} />
      <Route path="/signup" element={<RedirectIfAuth><SignUp /></RedirectIfAuth>} />
      <Route path="/login" element={<RedirectIfAuth><Login /></RedirectIfAuth>} />
      <Route path="/profile" element={<RequireAuth><IfBrokerToPanel><IfAdminToPanel><Profile /></IfAdminToPanel></IfBrokerToPanel></RequireAuth>} />
      <Route path="/admin" element={<RequireAdmin><AdminDashboard /></RequireAdmin>} />
      <Route path="/admin-users" element={<RequireAdmin><AdminUsers /></RequireAdmin>} />
      <Route path="/admin-brokers" element={<RequireAdmin><AdminBrokers /></RequireAdmin>} />
      <Route path="/admin-upload" element={<RequireAdmin><AdminUpload /></RequireAdmin>} />
      <Route path="/admin-edit/:id" element={<RequireAdmin><AdminEdit /></RequireAdmin>} />
      <Route path="/admin-chat/:userId" element={<RequireAdmin><AdminChat /></RequireAdmin>} />
      <Route path="/admin-chat" element={<RequireAdmin><Navigate to="/admin-chat/@" replace /></RequireAdmin>} />
      <Route path="/chat/manager" element={<RequireAuth><IfAdminToPanel><Chat /></IfAdminToPanel></RequireAuth>} />
      <Route path="/broker/offer" element={<Broker />}></Route>
      <Route path="/broker" element={<RequireBroker><BrokerPanel /></RequireBroker>}></Route>
      <Route path="/broker/upload" element={<RequireBroker><BrokerUpload /></RequireBroker>}></Route>
      <Route path="/broker/edit/:id" element={<RequireBroker><BrokerEdit /></RequireBroker>}></Route>
      <Route path="/broker/search" element={<RequireBroker><BrokerUsers /></RequireBroker>}></Route>
      <Route path="/policy/terms-and-conditions" element={<Terms />} />
    </Routes>
  </BrowserRouter>
);