import React, { useState, useEffect } from 'react';
import "./Header.css";
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import logo from '../assets/easyrentallogo.png';
import chat from '../assets/chat.png';
import { auth } from '../firebaseConfig.ts';

interface User {
  email?: string | null;
}

function Header() {
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation(); 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Helper function to determine if a path is the current location
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className='header'>
      <div className='header-container'>
        <div className='header-first'>
        <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
        </div>
        <div className='header-menu'>
          <Link to="/apartments" className={isActive('/apartments') ? 'header-menu-item active' : 'header-menu-item'} style={{ textDecoration: 'none', color: 'white' }}>Listings</Link>
          <Link to="/pricing" className={isActive('/pricing') ? 'header-menu-item active' : 'header-menu-item'} style={{ textDecoration: 'none', color: 'white' }}>Pricing</Link>
          <Link to="/broker/offer" className={isActive('/broker-offer') ? 'header-menu-item active' : 'header-menu-item'} style={{ textDecoration: 'none', color: 'white' }}>For brokers</Link>
        </div>
          {user ? (
            <>
            <div style={{display: 'flex', gap: '5px', alignItems: 'center', width: '33%', justifyContent: 'flex-end'}}>
            <Link to="/chat/manager" style={{ backgroundColor: '#141414', borderRadius: '10px', border: '1px solid #262626', padding: '12px 20px', textDecoration: 'none', color: 'white', gap: '7px', alignItems: 'center', display: 'flex' }}><img src={chat} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/></Link>
            <Link to="/profile" className='profile-header-icon' style={{ backgroundColor: '#141414', borderRadius: '10px', border: '1px solid #262626', padding: '12px 20px', textDecoration: 'none', color: 'white' }}>{user.email || 'User'}</Link>
            </div>
            </>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', width: '33%', justifyContent: 'flex-end'}}>
            <Link to="/login" style={{ backgroundColor: '#141414', borderRadius: '10px', border: '1px solid #262626', padding: '12px 20px', textDecoration: 'none', color: 'white' }}>Login</Link>
            </div>
          )}
      </div>
    </div>
  );
}

export default Header;
