import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig.ts'; // Adjust this import path to your Firebase config file
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import './index.css';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import pending from '../../assets/pending.png'
import verified from '../../assets/verified.png'
import BrokerHeader from '../../components/BrokerHeader.tsx';
import BrokerHeaderMobile from '../../components/BrokerHeaderMobile.tsx';
import surveyUpload from '../../assets/surveyupload.png';
import domToImage from 'dom-to-image';
import ReactDOM from 'react-dom';

interface Apartment {
  id: string;
  title: string;
  bedroomAmount: string;
  broker: string;
  description?: string;
  price?: string;
  locationString?: string;
  images: string[];
  isActive: boolean;
  apartmentId: string;
  approved: boolean;
}

interface BrokerData {
    id: string;
    email: string;
    fullname: string;
    phone: string;
    source: string;
    brokerStatus: string;
    brokerPromocode: string;
}

interface ApartmentDetails {
  brokerEmail: string;
  bedroomAmount: string;
  price: string;
}

interface BrokerInfo {
  email: string;
  fullname: string;
  phone: string;
}


function BrokerPanel() {
  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [filterActive, setFilterActive] = useState<boolean | null>(true);
  const navigate = useNavigate();
  const [userData, setUserData] = useState<BrokerData | null>(null);
  const [visibleApplications, setVisibleApplications] = useState({});


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const surveysCol = collection(db, "Surveys");
        const q = query(surveysCol, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userSurveyData = querySnapshot.docs[0].data();
          setUserData(userSurveyData as BrokerData);
        } else {
          console.log("No matching documents found!");
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const [appliedUsers, setAppliedUsers] = useState({});

  const fetchAppliedUsersForApartment = async (apartmentId) => {
    const appliesRef = collection(db, "Applies");
    const q = query(appliesRef, where("apartmentId", "==", apartmentId));
    const querySnapshot = await getDocs(q);
    
    const userDetailsWithStatus = await Promise.all(querySnapshot.docs.map(async (doc) => {
      const userStatus = doc.data().status;
      const userId = doc.data().userId;
      
      const surveysRef = collection(db, "Surveys");
      const userQ = query(surveysRef, where("uid", "==", userId));
      const userSnapshot = await getDocs(userQ);
      
      if (!userSnapshot.empty) {

        return { ...userSnapshot.docs[0].data(), status: userStatus, applyId: doc.id };
      }
      return null;
    }));
    
    return userDetailsWithStatus.filter(Boolean);
  };
  

  useEffect(() => {
    if (userData?.fullname) {
      const fetchApartments = async () => {
        const apartmentsRef = collection(db, "Apartments");
        const q = query(apartmentsRef, where("broker", "==", userData.fullname), where("isActive", "==", filterActive));
        const querySnapshot = await getDocs(q);
  
        const fetchedApartments: Apartment[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Apartment[];
  
        setApartments(fetchedApartments);

        const appliedUsersData = {};
        for (const apartment of fetchedApartments) {
          const users = await fetchAppliedUsersForApartment(apartment.id);
          // console.log(users)
          appliedUsersData[apartment.id] = users;
        }
        setAppliedUsers(appliedUsersData);
        };

    fetchApartments();
    };
      
  }, [userData, filterActive]);

  
  const archiveApartment = async (apartmentId: string) => {
    try {
      await updateDoc(doc(db, "Apartments", apartmentId), {
        isActive: false
      });
      setApartments(prevApartments => prevApartments.map(apartment => {
        if (apartment.id === apartmentId) {
          return { ...apartment, isActive: false };
        }
        return apartment;
      }));
      console.log('Apartment archived.')
    } catch (error) {
      console.error("Error archiving apartment:", error);
    }
  };

  const activateApartment = async (apartmentId: string) => {
    try {
      await updateDoc(doc(db, "Apartments", apartmentId), {
        isActive: true,
        created_at: serverTimestamp()
      });
      setApartments(prevApartments => prevApartments.map(apartment => {
        if (apartment.id === apartmentId) {
          return { ...apartment, isActive: true };
        }
        return apartment;
      }));
      console.log('Apartment is active again.')
    } catch (error) {
      console.error("Error archiving apartment:", error);
    }
  };

  const uploadPage = () => {
    navigate('/broker/upload');
  };

  const filterApartments = (isActive: boolean | null) => {
    setFilterActive(isActive);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out");
      navigate('/')
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  if (!userData) {
    return <div></div>;
  }

  const toggleApplicationsVisibility = (apartmentId) => {
    setVisibleApplications(prevState => ({
      ...prevState,
      [apartmentId]: !prevState[apartmentId],
    }));
  };


  const acceptApplication = async (apartmentId: string, userId: string) => {
    setAppliedUsers(prev => ({
      ...prev,
      [apartmentId]: prev[apartmentId].map(user =>
          user.uid === userId ? { ...user, status: 'Accepted' } : user
      )
  }));
    let userEmail = ""; // Default to empty string if email not found
    let userFullName = ""; // Default to empty string if full name not found
    let brokerFullName = ""; // Default for broker full name
    let brokerPhone = ""; // Default for broker phone

    // Fetch user's email from Surveys collection
    const userSurveysRef = collection(db, "Surveys");
    const userSurveysQuery = query(userSurveysRef, where("uid", "==", userId));
    const userSurveySnapshot = await getDocs(userSurveysQuery);
    if (!userSurveySnapshot.empty) {
      const userData = userSurveySnapshot.docs[0].data();
      userEmail = userData.email; // Assuming the field for the user's email is 'email'
      userFullName = userData.fullname; // Assuming the field for the user's full name is 'fullName'
    }

    // Fetch apartment details
    let apartmentDetails: ApartmentDetails | null = null;
    const apartmentRef = doc(db, "Apartments", apartmentId);
    const apartmentSnap = await getDoc(apartmentRef);
    if (apartmentSnap.exists()) {
      apartmentDetails = apartmentSnap.data() as ApartmentDetails;
      // Assuming the broker's UID is stored in apartment details under 'brokerUid'
      const brokerEmail = apartmentDetails.brokerEmail;
      let brokerInfo: BrokerInfo;
      // Fetch broker's information from Surveys collection using brokerUid
      const brokerSurveysRef = collection(db, "Surveys");
      const brokerSurveysQuery = query(brokerSurveysRef, where("email", "==", brokerEmail));
      const brokerSurveySnapshot = await getDocs(brokerSurveysQuery);
      if (!brokerSurveySnapshot.empty) {
        brokerInfo = brokerSurveySnapshot.docs[0].data() as BrokerInfo;
        brokerFullName = brokerInfo.fullname; // Assuming the field for the broker's full name is 'fullName'
        brokerPhone = brokerInfo.phone; // Assuming the field for the broker's phone is 'phone'
      }
    }

    // Update application status to "Accepted"
    const appliesRef = collection(db, "Applies");
    const q = query(appliesRef, where("apartmentId", "==", apartmentId), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const applicationDoc = querySnapshot.docs[0];
      await updateDoc(doc(db, "Applies", applicationDoc.id), {
        status: "Accepted"
      });
      console.log("Application accepted");

      // Send email to the user about the acceptance
      if (userEmail && apartmentDetails) {
        const mailRef = collection(db, "mail");
        await addDoc(mailRef, {
          to: [userEmail],
          message: {
            subject: "Your application has been accepted",
            text: `Congratulations, ${userFullName}! Your application has been accepted. 
                  Apartment details: ${apartmentDetails.bedroomAmount} bedroom(s), $${apartmentDetails.price}. 
                  View the apartment: https://easyrental-92cda.web.app/apartment/${apartmentId}. 
                  Contact the broker for viewing: ${brokerFullName}, ${brokerPhone}.`,
            html: `<p>Congratulations, ${userFullName}! Your application has been accepted.</p>
                  <p>Apartment: ${apartmentDetails.bedroomAmount} bedroom(s), $${apartmentDetails.price}.</p>
                  <p>View the apartment at: <a href="https://easyrental-92cda.web.app/apartment/${apartmentId}">Apartment Details</a>.</p>
                  <p>Contact the broker for viewing: ${brokerFullName}, ${brokerPhone}.</p>`
          }
        });
      }
    } else {
      console.log("No matching application found");
    }
};

  
const declineApplication = async (apartmentId: string, userId: string) => {
  let userEmail = ""; // Default to empty string if email not found
  let userFullName = ""; // Default to empty string if full name not found

  setAppliedUsers(prev => ({
    ...prev,
    [apartmentId]: prev[apartmentId].map(user =>
        user.uid === userId ? { ...user, status: 'Declined' } : user
    )
}));

  // Fetch user's email and full name from Surveys collection
  const userSurveysRef = collection(db, "Surveys");
  const userSurveysQuery = query(userSurveysRef, where("uid", "==", userId));
  const userSurveySnapshot = await getDocs(userSurveysQuery);
  if (!userSurveySnapshot.empty) {
    const userData = userSurveySnapshot.docs[0].data();
    userEmail = userData.email; // Assuming the field for the user's email is 'email'
    userFullName = userData.fullname; // Assuming the field for the user's full name is 'fullName'
  }

  // Fetch apartment details
  let apartmentDetails: ApartmentDetails | null = null;
  const apartmentRef = doc(db, "Apartments", apartmentId);
  const apartmentSnap = await getDoc(apartmentRef);
  if (apartmentSnap.exists()) {
    apartmentDetails = apartmentSnap.data() as ApartmentDetails;
  } else {
    console.log("Apartment details not found");
    return; // Exit the function if apartment details are not found
  }

  // Update application status to "Declined"
  const appliesRef = collection(db, "Applies");
  const q = query(appliesRef, where("apartmentId", "==", apartmentId), where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const applicationDoc = querySnapshot.docs[0];
    await updateDoc(doc(db, "Applies", applicationDoc.id), {
      status: "Declined"
    });
    console.log("Application declined");

    // Send email to the user about the declined application
    if (userEmail && apartmentDetails) {
      const mailRef = collection(db, "mail");
      await addDoc(mailRef, {
        to: [userEmail],
        message: {
          subject: "Your application has been declined",
          text: `${userFullName}!, your application has been declined. Apartment details: ${apartmentDetails.bedroomAmount} bedroom(s), $${apartmentDetails.price}. View the apartment: https://easyrental-92cda.web.app/apartment/${apartmentId}. Contact to manager to see why: https://easyrental-92cda.web.app/chat/manager`,
          html: `<p>${userFullName}!, your application has been declined.</p>
                 <p>Apartment details: ${apartmentDetails.bedroomAmount} bedroom(s), $${apartmentDetails.price}.</p>
                 <p>View the apartment at: <a href="https://easyrental-92cda.web.app/apartment/${apartmentId}">Apartment Details</a>.</p>
                 <p>Contact to manager to see why: <a href="https://easyrental-92cda.web.app/chat/manager">Contact Manager</a></p>`
        }
      });
    }
  } else {
    console.log("No matching application found");
  }
};

  
  const UserInfoCard = ({ user }) => {
    return (
      <div style={{ padding: '50px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '1000px', margin: '10px auto', fontFamily: 'Arial, sans-serif' }}>
        <img src={user.pfp} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover' }}/>
        <h2 style={{ fontSize: '62px', color: '#333' }}>{user.fullname}</h2>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Citizenship:</strong> {user.citizenship}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Tenants: </strong>{`${Number(user.adults) + Number(user.kids)} (${user.kids} kids)`}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Job Place:</strong> {user.placeOfWork}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Position:</strong> {user.position}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Salary:</strong> ${user.salary}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Credit Score:</strong> {user.creditScore}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Income Tax:</strong> ${user.incomeTax}</p>
        <p style={{ fontSize: '48px', color: '#333' }}><strong>Savings:</strong> ${user.savings}</p>
      </div>
    );
  };
  

  const downloadUserInfoAsImage = async (apartmentId, userId) => {
    // Find the apartment using the apartmentId
    const apartment = apartments.find(apartment => apartment.id === apartmentId);
  
    // Find the user data within the apartment's applied users
    const userData = appliedUsers[apartmentId]?.find(user => user.uid === userId);
  
    // Proceed only if userData and apartment are found
    if (!userData || !apartment) {
      console.error("User or apartment not found.");
      return;
    }
  
    // Temporarily render the UserInfoCard component for the user
    const tempDiv = document.createElement('div');
    document.body.appendChild(tempDiv);
  
    ReactDOM.render(<UserInfoCard user={userData} />, tempDiv);
  
    try {
      const dataUrl = await domToImage.toPng(tempDiv, {});
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `user-info-${userId}-${apartmentId}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('could not generate image', err);
    }
  
    // Cleanup
    document.body.removeChild(tempDiv);
  };
  
  



  return (
    <div className='dashboard-container'>
      <BrokerHeader />
      <BrokerHeaderMobile />
      <div className='dashboard-welcome'>
        <div className='apartments-title'>{userData?.fullname}</div>
        <div className='isBroker'>Broker</div>
        <button className='button-primary' onClick={handleLogout}>Logout</button>
      </div>

      {
        userData.brokerPromocode && (
          <div className='promocode-card'>
            <div className='promocode-title'>Your promocode: {userData.brokerPromocode}</div>

            <div className='broker-promocode-grid'>
                <div className='apartment-details-card-row-item'>
                  <div className='broker-promocode-grid-label'>Linked users</div>
                  <div className='apartment-details-card-row-item-title'>60</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='broker-promocode-grid-label'>Earned from users</div>
                  <div className='apartment-details-card-row-item-title'>$1200.00</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='broker-promocode-grid-label'>Earned from deals</div>
                  <div className='apartment-details-card-row-item-title'>$00.00</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='broker-promocode-grid-label'>Total earned this month</div>
                  <div className='apartment-details-card-row-item-title'>$1200.00</div>
                </div>
            </div>

            <div className='promocode-text'>You will get <b>$10/weekly</b> from every EasyRent user who used your promocode.</div>
            <div className='promocode-text'>You will get <b>$100</b> from all closed deals in EasyRent.</div>
            <div className='promocode-text'>In average EasyRent agents earn <b>$1300-2000/monthly</b> from our referral program.</div>
          </div>
        )
      }


        <div className='broker-details-card-profile'>
            <div className='broker-details-grid'>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Fullname</div>
                  <div className='apartment-details-card-row-item-title'>{userData.fullname}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Status</div>
                  <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                    <img 
                      src={userData.brokerStatus === "Verified" ? verified : pending} 
                      style={{objectFit: 'cover', width: '20px', height: '20px'}} 
                    />
                    <div className='apartment-details-card-row-item-title'>{userData.brokerStatus}</div>
                  </div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Phone</div>
                  <div className='apartment-details-card-row-item-title'>{userData.phone}</div>
                </div>
                <div className='apartment-details-card-row-item'>
                  <div className='apartment-details-card-row-item-label'>Email</div>
                  <div className='apartment-details-card-row-item-title'>{userData.email}</div>
                </div>
            </div>
        </div>
      <div className='apartment-item-buttons'>
      {userData && userData.brokerStatus === "Verified" && (
          <button className='button-active' onClick={uploadPage}>Upload new listing</button>
      )}
      <button className='button-primary' onClick={() => filterApartments(true)}>Active listings</button>
      <button className='button-primary' onClick={() => filterApartments(false)}>Archived listings</button>
      </div>


      <div className='apartments-list'>
        {apartments
          .filter(apartment => apartment.approved === false)
          .map((apartment) => (
            <div key={apartment.id} className='broker-apartment'>
            <div className='broker-apartment-item'>
              <div className='apartment-item-info'>
                <img alt={'Apartment'} className='apartment-item-media' src={apartment.images && apartment.images.length > 0 ? apartment.images[0] : 'https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultphoto.png?alt=media&token=c5f529c8-af52-429e-9ce2-290f9910d31c'} />
                <Link className='apartment-item-info-column' style={{textDecoration: 'none'}} to={`/apartment/${apartment.id}`}>
                  <div className='apartment-item-title'>{apartment.bedroomAmount} bedrooms, ${apartment.price}</div>
                  <div className='apartment-item-text'>{apartment.locationString?.split(',')[0]}, {apartment.broker}</div>
                  <div className='archived-text'>This listing is currently in pending</div>
                </Link>
              </div>
            </div>
            </div>
        ))}
      </div>


      <div className='apartments-list'>
        {apartments
          .filter(apartment => apartment.isActive === filterActive || apartment.approved === true)
          .map((apartment) => (
            <div key={apartment.id} className='broker-apartment'>
            <div className='broker-apartment-item'>
              <div className='apartment-item-info'>
                <img alt={'Apartment'} className='apartment-item-media' src={apartment.images && apartment.images.length > 0 ? apartment.images[0] : 'https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultphoto.png?alt=media&token=c5f529c8-af52-429e-9ce2-290f9910d31c'} />
                <Link className='apartment-item-info-column' style={{textDecoration: 'none'}} to={`/apartment/${apartment.id}`}>
                  <div className='apartment-item-title'>{apartment.bedroomAmount} bedrooms, ${apartment.price}</div>
                  <div className='apartment-item-text'>{apartment.locationString?.split(',')[0]}, {apartment.broker}</div>
                  {filterActive !== true && <div className='archived-text'>Archived</div>}
                  {filterActive !== false && <div className='active-text'>Active</div>}
                </Link>
              </div>
              <div className='apartment-item-buttons'>
              <button className='button-primary' onClick={() => toggleApplicationsVisibility(apartment.id)}>
                {visibleApplications[apartment.id] ? 'Hide applies' : 'Show applies'}
              </button>
                {filterActive !== true && <button className='button-primary'onClick={() => activateApartment(apartment.id)}>Activate</button>}
                {filterActive !== false && <button className='button-primary'onClick={() => archiveApartment(apartment.id)}>Archive</button>}
                {filterActive !== false &&  <button className='button-primary' onClick={() => navigate(`/broker/edit/${apartment.id}`)}>Edit</button>}
              </div>
            </div>
            
            {visibleApplications[apartment.id] && (
            <div className='broker-users-list'>
              {appliedUsers[apartment.id]?.map((user, index) => (
              <div id={`user-info-${user.uid}`} className='broker-user'>
              <div key={index} className='broker-user-details-grid'>
                  <img src={user.pfp} className='broker-user-pfp' />
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Fullname</div>
                    <div className='user-details-card-row-item-title'>{user.fullname}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                    <div className='user-details-card-row-item-label'>Citizenship</div>
                    <div className='user-details-card-row-item-title'>{user.citizenship}</div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Tenants</div>
                        <div className='user-details-card-row-item-title'>
                        {`${Number(user.adults) + Number(user.kids)} (${user.kids} kids)`}  
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Place of job</div>
                        <div className='user-details-card-row-item-title'>
                            {user.placeOfWork === 'Unemployed yet' 
                            ? 'No' 
                            : `${user.placeOfWork}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Position</div>
                        <div className='user-details-card-row-item-title'>
                            {user.position === 'Unemployed yet' 
                            ? 'No' 
                            : `${user.position}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Salary</div>
                        <div className='user-details-card-row-item-title'>
                            {user.salary === 'Unemployed yet' 
                            ? 'No' 
                            : `$${user.salary}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Credit Score</div>
                        <div className='user-details-card-row-item-title'>
                            {user.creditScore === `Don't Have Credit Score` 
                            ? 'No'
                            : `${user.creditScore}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Income Tax</div>
                        <div className='user-details-card-row-item-title'>
                            {user.incomeTax === `Don't Have Income Tax` 
                            ? 'No'
                            : `$${user.incomeTax}`}
                        </div>
                    </div>
                    <div className='user-details-card-row-item'>
                        <div className='user-details-card-row-item-label'>Savings</div>
                        <div className='user-details-card-row-item-title'>${user.savings}</div>
                    </div>
                </div>

                {user.status === 'Pending' && (
                  <div className='broker-user-buttons'>
                  <button className='broker-accept' onClick={() => acceptApplication(apartment.id, user.uid)}>Accept</button>
                  <button className='broker-decline' onClick={() => declineApplication(apartment.id, user.uid)}>Decline</button>
                  <button className='button-survey-upload' onClick={() => downloadUserInfoAsImage(apartment.id, user.uid)}><img style={{width: '20px', height: '20px', objectFit: 'cover'}} src={surveyUpload} /></button>
                  </div>
                )}

                {user.status === 'Declined' && (
                  <div className='broker-user-buttons'>
                  <div className='broker-decline'>
                    Declined
                  </div>
                  <button className='button-survey-upload' onClick={() => downloadUserInfoAsImage(apartment.id, user.uid)}><img style={{width: '20px', height: '20px', objectFit: 'cover'}} src={surveyUpload} /></button>
                  </div>
                )}

                {user.status === 'Accepted' && (
                  <div className='broker-user-buttons'>
                  <div className='broker-accept'>
                    Accepted
                  </div>
                  <div className='broker-accept'>
                    <div>{user.phone}</div>
                  </div>
                  <button className='button-survey-upload' onClick={() => downloadUserInfoAsImage(apartment.id, user.uid)}><img style={{width: '20px', height: '20px', objectFit: 'cover'}} src={surveyUpload} /></button>
                  </div>
                )}

              </div>
              ))}
              </div>
               )}
            </div>
        ))}
      </div>
    </div>
  )
}

export default BrokerPanel