import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, Timestamp, limit, getDocs, writeBatch } from 'firebase/firestore';
import { db, auth } from '../../firebaseConfig.ts';
import { onAuthStateChanged } from 'firebase/auth';
import './index.css'
import docsend from '../../assets/docsend.png'
import imagesend from '../../assets/imagesend.png'
import searchicon from '../../assets/searchicon.png'
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminHeader from '../../components/AdminHeader.tsx';
import arrow from '../../assets/arrow.png'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import chat from '../../assets/chat.png';
import phone from '../../assets/phone.png'

interface Message {
  id: string;
  from: string;
  to: string;
  message: string;
  timestamp: Timestamp;
  unread: boolean;
  type: 'text' | 'photo' | 'document' | 'apartment';
  fileUrl?: string;
  apartmentDetailsPhoto;
  apartmentDetailsLocation;
  apartmentDetailsBedroom;
  apartmentDetailsPrice;
}

interface UserSurveyData {
  uid: string;
  fullname: string;
  pfp: string;
  lastMessage;
  phone: string;
  citizenship: string;
  adults: string;
  kids: string;
  placeOfWork: string;
  position: string;
  salary: string;
  creditScore: string;
  incomeTax: string;
  savings: string;
  isPremium: boolean;
}

const AdminChat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const { userId } = useParams();
  const [currentUserUid, setCurrentUserUid] = useState<string | null>(null);
  const [userSurveyData, setUserSurveyData] = useState<UserSurveyData | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [usersWithChat, setUsersWithChat] = useState<UserSurveyData[]>([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isMobileChat, setIsMobileChat] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<{ users: UserSurveyData[]; messages: Message[] }>({ users: [], messages: [] });


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid);
      } else {
        setCurrentUserUid(null);
      }
    });
  
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const q = query(collection(db, 'Surveys'));
    onSnapshot(q, (querySnapshot) => {
      const users: UserSurveyData[] = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data() as UserSurveyData;
        if (userData.uid !== currentUserUid) {
          users.push(userData);
        }
      });
    });

  }, [currentUserUid]);

  useEffect(() => {
    const q = query(collection(db, 'Surveys'), where('uid', '==', userId));
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setUserSurveyData(doc.data() as UserSurveyData);
      });
    });
  }, [userId]);
  
  useEffect(() => {
    if (!currentUserUid) return;
  
    // Listen for messages sent to or from the currentUserUid
    const messagesRef = collection(db, 'Messages');
    const queryConstraints = [
      where('timestamp', '>', Timestamp.fromDate(new Date(0))),
      orderBy('timestamp', 'desc'),
    ];
    const messagesQuery = query(messagesRef, ...queryConstraints);
  
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "modified") {
          const message = { id: change.doc.id, ...change.doc.data() } as Message;
          // Determine if this message involves the current user
          if (message.from === currentUserUid || message.to === currentUserUid) {
            // Update the sidebar for the user involved in this message
            await updateSidebarForUser(message);
          }
        }
      });
    });
  
    return () => unsubscribe();
  }, [currentUserUid]);

  const updateSidebarForUser = async (message: Message) => {
    setUsersWithChat(prevUsers => {
      let userFound = false;
      const updatedUsers = prevUsers.map(user => {
        if (user.uid === message.from || user.uid === message.to) {
          userFound = true;
          return { ...user, lastMessage: message };
        }
        return user;
      });
  
      if (!userFound) {
      }
  
      return sortUsersByLastMessage(updatedUsers);
    });
  };
  

  useEffect(() => {
    const fetchUsersAndLastMessages = async () => {
      const q = query(collection(db, 'Surveys'));
      const usersSnapshot = await getDocs(q);
      
      // This map will run each promise concurrently
      const usersWithLastMessagesAndPremiumStatusPromises = usersSnapshot.docs.map(async (doc) => {
        const userData = { ...doc.data(), id: doc.id } as unknown as UserSurveyData;
        
        // Check for current user to avoid adding them
        if (userData.uid === currentUserUid) return null;
        
        // Check if the user is premium
        const isPremium = await checkIfUserIsPremium(userData.uid);
        
        // Fetch the last message for each user
        const lastMsgQuery = query(collection(db, 'Messages'), 
          where('from', 'in', [currentUserUid, userData.uid]), 
          where('to', 'in', [currentUserUid, userData.uid]), 
          orderBy('timestamp', 'desc'), 
          limit(1));
        
        const lastMsgSnapshot = await getDocs(lastMsgQuery);
        
        let lastMessage: Message | null = null;
        lastMsgSnapshot.forEach((msgDoc) => {
          lastMessage = { id: msgDoc.id, ...msgDoc.data() } as Message;
        });
        
        // Return null if there is no last message and user isn't premium (if you want to filter out users without messages)
        if (!lastMessage && !isPremium) return null;
        
        return { ...userData, lastMessage, isPremium }; // Include premium status in user data
      });
      
      const resolvedUsers = await Promise.all(usersWithLastMessagesAndPremiumStatusPromises);
      const filteredUsers = resolvedUsers.filter((user): user is UserSurveyData => user !== null);
      const sortedUsers = sortUsersByLastMessage(filteredUsers);
      
      setUsersWithChat(sortedUsers);
    };
  
    fetchUsersAndLastMessages();
  
  }, [currentUserUid]);
  
  // Utility function to check if user is premium
  const checkIfUserIsPremium = async (userId) => {
    const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
    const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
    const subscriptionSnapshot = await getDocs(q);
    return !subscriptionSnapshot.empty; // True if at least one active or trialing subscription exists
  };
  
  // Assuming `sortUsersByLastMessage` is already defined and sorts users based on the timestamp of their last message.
   
  

  const sortUsersByLastMessage = (users) => {
    return users.sort((a, b) => {
      const timestampA = a.lastMessage?.timestamp?.toDate().getTime() || 0;
      const timestampB = b.lastMessage?.timestamp?.toDate().getTime() || 0;
      return timestampB - timestampA;
    });
  };
  


  // Subscribing to messages
  useEffect(() => {
    if (currentUserUid) {
      const msgsQuery = query(collection(db, 'Messages'),
      where('from', 'in', [currentUserUid, userId]),
      where('to', 'in', [currentUserUid, userId]),
      orderBy('timestamp'));
      const unsubscribe = onSnapshot(msgsQuery, (querySnapshot) => {
        const msgs: Message[] = [];
        querySnapshot.forEach((doc) => {
          msgs.push({ id: doc.id, ...doc.data() } as Message);
        });
        setMessages(msgs);
      });
  
      return () => unsubscribe();
    }
  }, [currentUserUid, userId]);



  
  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
  
    messages.forEach((msg) => {
      const date = msg.timestamp.toDate();
      const dateKey = date.toISOString().split('T')[0];
  
      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
  
      groupedMessages[dateKey].push(msg);
    });
  
    return groupedMessages;
  };

  const getLabelForDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    const dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0); // Normalize message date to start of day
  
    if (dateObj.getTime() === today.getTime()) return 'Today';
    if (dateObj.getTime() === yesterday.getTime()) return 'Yesterday';
  
    return dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };
  
  

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);


const uploadFileToStorage = async (file) => {
  const storage = getStorage();
  const storageRef = ref(storage, `uploads/${file.name}_${Date.now()}`);

  try {
    // Upload file to the reference path in Firebase Storage
    const uploadTaskSnapshot = await uploadBytesResumable(storageRef, file);
    // Get the download URL after successful upload
    const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Upload failed", error);
    throw new Error("Failed to upload file.");
  }
};

const determineMessageType = (file) => {
  const fileType = file.type;
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const validDocumentTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more MIME types as needed

  if (validImageTypes.includes(fileType)) {
    return 'photo';
  } else if (validDocumentTypes.includes(fileType)) {
    return 'document';
  } else {
    // Default or other specific types could be handled here
    return 'other';
  }
};

const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files ? event.target.files[0] : null;
  if (file) {
    setUploadedFile(file);
  }
};

  
const handleSendMessage = async (e?: React.SyntheticEvent) => {
  e?.preventDefault();  // This will handle both mouse and keyboard events
  
  if (!newMessage.trim() && !uploadedFile) return;

  let messageType = 'text'; // Default message type
  let messageContent = newMessage; // The text content of the message
  let fileUrl: string | null = null;

  // Check if there's an uploaded file
  if (uploadedFile) {
    try {
      // Upload the file and get the URL
      setNewMessage('')
      fileUrl = await uploadFileToStorage(uploadedFile);
      // Determine the message type based on the file
      messageType = determineMessageType(uploadedFile);
      // Use the file name as the message content or any placeholder text
      messageContent = uploadedFile.name; // Or any placeholder text you want to show in the message
    } catch (error) {
      console.error("Error uploading file: ", error);
      return; // Exit the function if the file upload fails
    } finally {
      // Regardless of success or failure, reset the uploadedFile state
      setUploadedFile(null);
    }
  }

  // Proceed with resetting the message input and sending the message only if no file upload is attempted or if it succeeds
  setNewMessage('');

  // Add the message details to Firestore
  await addDoc(collection(db, 'Messages'), {
    from: currentUserUid,
    to: userId,
    message: messageContent,
    timestamp: Timestamp.now(),
    unread: true,
    type: messageType,
    fileUrl: fileUrl,
  });
};



  

  const groupedMessages = groupMessagesByDate(messages);

  const showUserDataPopup = () => {
    setIsPopupVisible(true);
  };

  const closeUserDataPopup = () => {
    setIsPopupVisible(false);
  };  

  const formatDateToTimeString = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // Format hours and minutes to always be two digits
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  };

  const goToMobileChat = () => {
    setIsMobileChat(true);
  };

  const goToMobileSidebar = () => {
    setIsMobileChat(false);
  };


  useEffect(() => {
    // Fetch and filter users
    const fetchFilteredUsers = async () => {
      const usersQuery = query(collection(db, 'Surveys'), orderBy('fullname'));
      const querySnapshot = await getDocs(usersQuery);
      const filteredUsers = querySnapshot.docs.map(doc => {
        const userData = doc.data() as UserSurveyData;
        return {
          ...userData,
          id: doc.id // Ensuring 'id' from the document is set after spreading userData, so it takes precedence
        };
      }).filter(user => user.fullname.toLowerCase().includes(searchTerm.toLowerCase()));
    
      const messagesQuery = query(collection(db, 'Messages'), orderBy('timestamp', 'desc'));
      const messagesSnapshot = await getDocs(messagesQuery);
      const filteredMessages = messagesSnapshot.docs.map(doc => {
        const messageData = doc.data() as Message;
        return {
          ...messageData,
          id: doc.id // Applying the same pattern here for messages
        };
      }).filter(message => message.message.toLowerCase().includes(searchTerm.toLowerCase()));
    
      setSearchResults({ users: filteredUsers, messages: filteredMessages });
    };
    

    if (searchTerm.trim()) {
      fetchFilteredUsers();
    } else {
      // Reset search results if search term is cleared
      setSearchResults({ users: [], messages: [] });
    }
  }, [searchTerm]);


  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Phone number copied to clipboard!');  // Optionally show a confirmation message
    } catch (err) {
      console.error('Failed to copy: ', err);  // Error handling
    }
  }
  

  

  return (
    <div className='chat-container'>
    <div className='header-chat'>
        <AdminHeader />
    </div>
    <div className='chat-admin'>
      

      <div className='sidebar-users-admin'>

        <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className='search-input' style={{padding: '14px 14px 14px 50px', backgroundColor: '#242424', borderRadius: '8px', color: '#999999', fontSize: '16px', margin: '20px 20px 20px 20px', border: 'none'}}/>
        <img alt='Search Icon' src={searchicon} style={{width: '20px', height: '20px', objectFit: 'cover', position: 'absolute', marginTop: '32px', marginLeft: '36px'}} />

        {searchTerm.trim().length > 0 ? (
          <>
        {searchResults.users.map((user) => (
          <Link to={`/admin-chat/${user.uid}`} className='chat-sidebar-user-admin' key={user.uid}>
            <img className='chat-one-user-pfp' alt='Chat User Pfp' src={user.pfp}/>
          <div className='chat-one-user-column'>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <div className='chat-one-username'>{user.fullname}</div>
            { user.isPremium ? (<div className='chatIsPremium'>Premium</div>) : ''}
            </div>
            <div className={`${user.uid === userId ? 'chat-one-text-active' : 'chat-one-text'}`} >
            {user.lastMessage ? user.lastMessage.message : "No messages yet"}
            </div>
          </div>
            <div className='chat-sidebar-date'>
                {user.lastMessage ? user.lastMessage.timestamp.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : ""}
            </div>
          </Link>
        ))}

        {searchTerm.trim() && searchResults.messages.map((message) => (
          <div key={message.id}>
            <div className='chat-one-text' >
            {message.message}
            </div>
          </div>
        ))}
         </>
        ) : (
          <>
        {usersWithChat.map((user) => (
        <Link to={`/admin-chat/${user.uid}`}
        className={`${user.uid === userId ? 'chat-sidebar-user-admin-active' : 'chat-sidebar-user-admin '}`} 
        key={user.uid}>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src={user.pfp}/>
          <div className='chat-one-user-column'>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <div className='chat-one-username'>{user.fullname}</div>
            { user.isPremium ? (<div className='chatIsPremium'>Premium</div>) : ''}
            </div>
            <div className={`${user.uid === userId ? 'chat-one-text-active' : 'chat-one-text'}`} >
            {user.lastMessage ? user.lastMessage.message : "No messages yet"}
            </div>
          </div>
            <div className='chat-sidebar-date'>
                {user.lastMessage ? user.lastMessage.timestamp.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : ""}
            </div>

        </Link>
        ))}
        </>
         )}
      </div>


      <div className='chat-messages'>
      {isPopupVisible && userSurveyData && (
    <div className="popup-chat">
      <div className='popup-fullname'>{userSurveyData.fullname}</div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Phone</div>
      <div className='popup-chat-info'>{userSurveyData.phone}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Citizenship</div>
      <div className='popup-chat-info'>{userSurveyData.citizenship}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Tenants</div>
      <div className='popup-chat-info'>
      {`${Number(userSurveyData.adults) + Number(userSurveyData.kids)} (${userSurveyData.kids} kids)`}
      </div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Place Of Job</div>
      <div className='popup-chat-info'>{userSurveyData.placeOfWork}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Position</div>
      <div className='popup-chat-info'>{userSurveyData.position}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Salary</div>
      <div className='popup-chat-info'>${userSurveyData.salary} / mo</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Credit Score</div>
      <div className='popup-chat-info'>{userSurveyData.creditScore}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Income Tax</div>
      <div className='popup-chat-info'>${userSurveyData.incomeTax}</div>
      </div>

      <div className='popup-chat-row'>
      <div className='popup-chat-info'>Savings</div>
      <div className='popup-chat-info'>${userSurveyData.savings}</div>
      </div>

      <button className='popup-chat-x' onClick={closeUserDataPopup}>x</button>
    </div>
    )}
      {userSurveyData ? (
        <div className='chat-one-user' style={{cursor: 'pointer'}} >
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src={userSurveyData.pfp}/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>{userSurveyData.fullname}</div>
            <div className='chat-one-text' onClick={showUserDataPopup}>Click here for client info</div>
          </div>
          <button onClick={() => copyToClipboard(userSurveyData.phone)} className='phone-button'>
            <img src={phone} className='phone-button-icon'/>
          </button>
        </div>
      ) : (
        <div className='chat-one-user'>
          <img className='chat-one-user-pfp' alt='Chat User Pfp' src='https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultpfp.png?alt=media&token=0cd3905f-ff0a-4f82-b771-97fe71583c87'/>
          <div className='chat-one-user-column'>
            <div className='chat-one-username'>Loading..</div>
            <div className='chat-one-text'>Personal Manager</div>
          </div>
        </div>
      )}

        <div className='messages'>
        {Object.keys(groupedMessages).map((date) => (
          <div className='groupped-messages' key={date}>
            <div className="date-label">{getLabelForDate(date)}</div>
            {groupedMessages[date].map((msg) => (
              <div key={msg.id} className={`message ${msg.type === 'photo' ? (msg.from === currentUserUid ? 'message-photo-sent' : 'message-photo-received') : (msg.from === currentUserUid ? 'message-sent' : 'message-received')}`}>
                {msg.type === 'text' && (
                  <div className='message-text'>{msg.message}</div>
                )}
                {msg.type === 'photo' && (
                  <img src={msg.fileUrl} alt="Photo" className="message-photo" />
                )}
                {msg.type === 'document' && (
                  <a href={msg.fileUrl} target="_blank" className='document-message'>
                  <div className="media-sector-media-pdf">
                      pdf
                  </div>
                  
                  <div className='media-sector-column'>
                  <div className='document-message-name'>{msg.message}</div>
                  <div className='document-message-click'>Click to open</div>
                  </div>
                  </a>
                )}
                  {msg.type === 'apartment' && (
                  <><Link to={msg.apartmentDetailsLink} className='document-message'>
                    <img src={msg.fileUrl} alt="Photo" className="message-apartment-photo" />

                    <div className='media-sector-column'>
                      <div className='document-message-name'>{msg.apartmentDetailsBedroom}, {msg.apartmentDetailsPrice}</div>
                      <div className='document-message-click'>{msg.apartmentDetailsLocation}</div>
                    </div>


                  </Link><div className='message-text' style={{marginTop: '10px'}}>{msg.message}</div></>
                )}

                <div className={(msg.type === "photo" ? 'message-photo-date' : 'message-date')}>
                  {formatDateToTimeString(msg.timestamp.toDate())}
                </div>
              </div>
            ))}
          </div>
        ))}
         <div ref={messagesEndRef} /> 
        </div>



        {uploadedFile && (
        <div className='media-sector'>
          {uploadedFile && (
            determineMessageType(uploadedFile) === 'photo' ? (
              <>
              <img src={URL.createObjectURL(uploadedFile)} className='media-sector-media' alt="Uploaded Preview" />
              <div className='media-sector-column'>
                <div className='media-sector-column-text'>Sending photo</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
              </>
            ) : (
              <>
              <a href={URL.createObjectURL(uploadedFile)} download={uploadedFile.name} className='media-sector-media-pdf'>
                pdf
              </a>
              <div className='media-sector-column'>

              <div className='media-sector-column-text'>Sending document</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
                      </>
            )
          )}
        </div>
        )}



      <div className='messages-type'>
      <div className='icons-row'>
      <input
        id="upload-photo"
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
      <input
        id="upload-doc"
        type="file"
        accept=".pdf,.docx"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />

        <label htmlFor='upload-photo'><img alt='Chat Icon' src={imagesend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
        <label htmlFor='upload-doc'><img alt='Chat Icon' src={docsend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
      </div>
      <input
        type="text"
        value={newMessage}
        placeholder='Type a message'
        onChange={(e) => setNewMessage(e.target.value)}
        className='message-input'
      />
      <button 
        onClick={handleSendMessage}
        className='send-button'>
        <img src={chat} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/>
      </button>
      </div>
    </div>
    </div>


    <div className='chat-admin-mobile'>
      
      {(!isMobileChat) && (
        <>
        <AdminHeader />
        <div className='sidebar-users-admin'>
  
          <input type="text" placeholder="Search" className='search-input' style={{padding: '14px 14px 14px 50px', backgroundColor: '#242424', borderRadius: '8px', color: '#999999', fontSize: '16px', margin: '20px 20px 20px 20px', border: 'none'}}/>
          <img alt='Search Icon' src={searchicon} style={{width: '20px', height: '20px', objectFit: 'cover', position: 'absolute', marginTop: '32px', marginLeft: '36px'}} />
  
          {usersWithChat.map((user) => (
          <Link onClick={goToMobileChat} to={`/admin-chat/${user.uid}`}
          className={`${user.uid === userId ? 'chat-sidebar-user-admin-active' : 'chat-sidebar-user-admin '}`} 
          key={user.uid}>
            <img className='chat-one-user-pfp' alt='Chat User Pfp' src={user.pfp}/>
            <div className='chat-one-user-column'>
            <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
              <div className='chat-one-username'>{user.fullname}</div>
              { user.isPremium ? (<div className='chatIsPremium'>Premium</div>) : ''}
              </div>
              <div className={`${user.uid === userId ? 'chat-one-text-active' : 'chat-one-text'}`} >
              {user.lastMessage ? user.lastMessage.message : "No messages yet"}
              </div>
            </div>
              <div className='chat-sidebar-date'>
                  {user.lastMessage ? user.lastMessage.timestamp.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : ""}
              </div>
  
          </Link>
          ))}
  
        </div>
        </>
        )}
  
        {(isMobileChat) && (
        <div className='chat-messages'>
        {isPopupVisible && userSurveyData && (
      <div className="popup-chat">
        <div className='popup-fullname'>{userSurveyData.fullname}</div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Phone</div>
        <div className='popup-chat-info'>{userSurveyData.phone}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Citizenship</div>
        <div className='popup-chat-info'>{userSurveyData.citizenship}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Tenants</div>
        <div className='popup-chat-info'>
        {`${Number(userSurveyData.adults) + Number(userSurveyData.kids)} (${userSurveyData.kids} kids)`}
        </div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Place Of Job</div>
        <div className='popup-chat-info'>{userSurveyData.placeOfWork}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Position</div>
        <div className='popup-chat-info'>{userSurveyData.position}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Salary</div>
        <div className='popup-chat-info'>${userSurveyData.salary} / mo</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Credit Score</div>
        <div className='popup-chat-info'>{userSurveyData.creditScore}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Income Tax</div>
        <div className='popup-chat-info'>${userSurveyData.incomeTax}</div>
        </div>
  
        <div className='popup-chat-row'>
        <div className='popup-chat-info'>Savings</div>
        <div className='popup-chat-info'>${userSurveyData.savings}</div>
        </div>
  
        <button className='popup-chat-x' onClick={closeUserDataPopup}>x</button>
      </div>
      )}
        {userSurveyData ? (
          <div className='chat-one-user' style={{cursor: 'pointer'}} >
            <button onClick={goToMobileSidebar} className='arrow-chat-icon'><img src={arrow} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/></button>
            <img className='chat-one-user-pfp' alt='Chat User Pfp' src={userSurveyData.pfp}/>
            <div className='chat-one-user-column'>
              <div className='chat-one-username'>{userSurveyData.fullname}</div>
              <div className='chat-one-text' onClick={showUserDataPopup}>Click here for client info</div>
            </div>
            <a href={`tel:${userSurveyData.phone}`} className='phone-button'>
              <img src={phone} className='phone-button-icon'/>
            </a>
          </div>
        ) : (
          <div className='chat-one-user'>
            <img className='chat-one-user-pfp' alt='Chat User Pfp' src='https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultpfp.png?alt=media&token=0cd3905f-ff0a-4f82-b771-97fe71583c87'/>
            <div className='chat-one-user-column'>
              <div className='chat-one-username'>Loading..</div>
              <div className='chat-one-text'>Personal Manager</div>
            </div>
          </div>
        )}
  
  <div className='messages'>
        {Object.keys(groupedMessages).map((date) => (
          <div className='groupped-messages' key={date}>
            <div className="date-label">{getLabelForDate(date)}</div>
            {groupedMessages[date].map((msg) => (
              <div key={msg.id} className={`message ${msg.type === 'photo' ? (msg.from === currentUserUid ? 'message-photo-sent' : 'message-photo-received') : (msg.from === currentUserUid ? 'message-sent' : 'message-received')}`}>
                {msg.type === 'text' && (
                  <div className='message-text'>{msg.message}</div>
                )}
                {msg.type === 'photo' && (
                  <img src={msg.fileUrl} alt="Photo" className="message-photo" />
                )}
                {msg.type === 'document' && (
                  <a href={msg.fileUrl} target="_blank" className='document-message'>
                  <div className="media-sector-media-pdf">
                      pdf
                  </div>
                  
                  <div className='media-sector-column'>
                  <div className='document-message-name'>{msg.message}</div>
                  <div className='document-message-click'>Click to open</div>
                  </div>
                  </a>
                )}
                {msg.type === 'apartment' && (
                  <><Link to={msg.apartmentDetailsLink} className='document-message'>
                    <img src={msg.fileUrl} alt="Photo" className="message-apartment-photo" />

                    <div className='media-sector-column'>
                      <div className='document-message-name'>{msg.apartmentDetailsBedroom}, {msg.apartmentDetailsPrice}</div>
                      <div className='document-message-click'>{msg.apartmentDetailsLocation}</div>
                    </div>


                  </Link><div className='message-text' style={{marginTop: '10px'}}>{msg.message}</div></>
                )}

                <div className={(msg.type === "photo" ? 'message-photo-date' : 'message-date')}>
                  {formatDateToTimeString(msg.timestamp.toDate())}
                </div>
              </div>
            ))}
          </div>
        ))}
         <div ref={messagesEndRef} /> 
        </div>

    
        {uploadedFile && (
        <div className='media-sector'>
          {uploadedFile && (
            determineMessageType(uploadedFile) === 'photo' ? (
              <>
              <img src={URL.createObjectURL(uploadedFile)} className='media-sector-media' alt="Uploaded Preview" />
              <div className='media-sector-column'>
                <div className='media-sector-column-text'>Sending photo</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
              </>
            ) : (
              <>
              <a href={URL.createObjectURL(uploadedFile)} download={uploadedFile.name} className='media-sector-media-pdf'>
                pdf
              </a>
              <div className='media-sector-column'>

              <div className='media-sector-column-text'>Sending document</div>
                <div className='media-sector-column-name'>{uploadedFile.name}</div>
              </div>
                      </>
            )
          )}
        </div>
        )}


        <div className='messages-type'>
      <div className='icons-row'>
      <input
        id="upload-photo"
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
      <input
        id="upload-doc"
        type="file"
        accept=".pdf,.docx"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />

        <label htmlFor='upload-photo'><img alt='Chat Icon' src={imagesend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
        <label htmlFor='upload-doc'><img alt='Chat Icon' src={docsend} style={{width: '20px', height: '20px', objectFit: 'cover'}} /></label>
      </div>
      <input
        type="text"
        value={newMessage}
        placeholder='Type a message'
        onChange={(e) => setNewMessage(e.target.value)}
        className='message-input'
      />
      <button 
        onClick={handleSendMessage}
        className='send-button'>
        <img src={chat} alt="Chat" style={{ height: '16px', objectFit: 'cover' }}/>
      </button>
      </div>
      </div>
      )}
      </div>
    </div>
  );
};

export default AdminChat;
