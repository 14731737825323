import React, { useEffect, useState } from 'react';
import './index.css'
import { db, auth } from '../../firebaseConfig.ts'; 
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, query, getDocs, where } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';
import MultiSelectCheckbox from '../../components/MultiSelectCheckbox.tsx';

function SignUp() {
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    promoCode: '',
    fullname: '',
    citizenship: '',
    phone: '',
    adults: '',
    kids: '',
    placeOfWork: '',
    position: '',
    salary: '',
    unemployed: false,
    cityID: false,
    greencard: false,
    driverLicense: false,
    ead: false,
    ssn: false,
    previousLandlordLetter: false,
    creditScore: '',
    noCreditScore: false,
    incomeTax: '',
    noIncomeTax: false,
    savings: '',
    lookingBedrooms: '',
    lookingArea: [],
    lookingPrice: '',
    pfp: null,
    role: 'user'
  });

  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);

  const validatePromoCode = async (code) => {
    const promocodesRef = collection(db, "Promocodes");
    const q = query(promocodesRef, where("code", "==", code));
    const querySnapshot = await getDocs(q);
  
    let promoCodeId = null;
    querySnapshot.forEach((doc) => {
      promoCodeId = doc.data().id;
    });
  
    return promoCodeId;
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
  
    if (type === 'file') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
      const file = files[0];
      setPreviewUrl(URL.createObjectURL(file));
    } else
    if (name === 'unemployed' && checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        placeOfWork: 'Unemployed yet',
        position: 'Unemployed yet',
        salary: 'Unemployed yet',
        [name]: checked,
      }));
    } else if (name === 'noCreditScore' && checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        creditScore: `Don't Have Credit Score`,
        [name]: checked,
      }));
    } else if (name === 'noIncomeTax' && checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        incomeTax: `Don't Have Income Tax`,
        [name]: checked,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  
  

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      lookingArea: selectedAreas,
    }));
  }, [selectedAreas]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const promoCodeId = await validatePromoCode(formData.promoCode);
    if (formData.promoCode && !promoCodeId) {
      setFormError("Wrong promo code!");
      return;
    }
  
    setFormError("");

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      let pfpUrl = "https://firebasestorage.googleapis.com/v0/b/easyrental-92cda.appspot.com/o/defaultpfp.png?alt=media&token=0cd3905f-ff0a-4f82-b771-97fe71583c87";
      if (formData.pfp) {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePhotos/${user.uid}`);
        
        // Upload photo and wait for completion
        await uploadBytes(storageRef, formData.pfp).then(async () => {
          // Once upload is complete, get the URL
          pfpUrl = await getDownloadURL(storageRef);
        });
      }

      const { email, password, pfp, promoCode, ...surveyData } = formData;

      await addDoc(collection(db, "Surveys"), {
        uid: user.uid,
        pfp: pfpUrl,
        promoCode: promoCodeId,
        ...surveyData
      });

      console.log("User created and survey data saved:", user.uid);
      navigate('/profile');
    } catch (error) {
      console.error("Error signing up:", error.message);
    }
};


  return (
    <form onSubmit={handleSubmit} className='signup-form'>
      <h2>Sign Up</h2>
      
      <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
      <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
      <input type="text" name="promoCode" placeholder="Promocode" onChange={handleChange} />
      {formError && <div className="forms-text">{formError}</div>}
      <div className='forms-text'>Filling out the application will help landlords quickly find a future tenant. Users with a completed application find housing 4 times more often!</div>
      <div className='forms-title'>General</div>
      <input type="text" name="fullname" placeholder="Full Name" onChange={handleChange} required />
      <select name="citizenship" value={formData.citizenship} onChange={handleChange} required>
            <option value="">Citizenship</option>
            <option value="United States">United States</option>
            <option value="Mexico">Mexico</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Ukraine">Ukraine</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Belarus">Belarus</option>
            <option value="Russia">Russia</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Armenia">Armenia</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Moldova">Moldova</option>
            <option value="Georgia">Georgia</option>
            <option value="Turkmenistan">Turkmenistan</option>
      </select>
      <input type="text" name="phone" placeholder="Phone" onChange={handleChange} required />

      <div className='forms-title'>What are you looking for?</div>
      <select name="lookingBedrooms" value={formData.lookingBedrooms} onChange={handleChange} required>
            <option value="">How many bedrooms?</option>
            <option value="room">A room</option>
            <option value="studio">Studio</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
      </select>
      <input type="number" name="lookingPrice" placeholder="What is your max budget?" min="0" onChange={handleChange} required />
      <MultiSelectCheckbox
          options={['Bensonhurst', 'Sheepshead Bay', 'Homecrest', 'Midwood', 'Dyker Heights', 'Borough Park', 'Kensignton', 'Flatbush', 'Flatlands', 'Bergen Beach', 'Mill Basin', 'Sunset Park', 'Bay Ridge', 'Gravesend', 'Bath Beach', 'Manhattan', 'Queens', 'Staten Island']}
          selectedOptions={selectedAreas}
          setSelectedOptions={setSelectedAreas}
        />

      <div className='forms-title'>How many tenants will live?</div>
      <input type="number" name="adults" placeholder="Adults Amount (Tenants Above 18yo)" min="0" onChange={handleChange} required />
      <input type="number" name="kids" placeholder="Kids Amount (Tenants Under 18yo)" min="0" onChange={handleChange} required />
      <div style={{color: 'white'}}>
      {`${Number(formData.adults) + Number(formData.kids)} tenants (${formData.adults} adults, ${formData.kids} kids)`}
      </div>
      <div className='forms-title'>Job Details</div>
      {!formData.unemployed && (
        <>
          <input type="text" name="placeOfWork" placeholder="Place of Work" onChange={handleChange} required={!formData.unemployed} />
          <input type="text" name="position" placeholder="Position" onChange={handleChange} required={!formData.unemployed} />
          <input type="number" name="salary" placeholder="Salary" min="0" onChange={handleChange} required={!formData.unemployed} />
        </>
      )}
      <label>
        <input type="checkbox" name="unemployed" onChange={handleChange} /> Unemployed Yet
      </label>
      

      <div className='forms-title'>Documents</div>
      <label>
        <input type="checkbox" name="cityID" onChange={handleChange} /> City ID (IDNYC)
      </label>
      <label>
        <input type="checkbox" name="greencard" onChange={handleChange} /> Greencard
      </label>
      <label>
        <input type="checkbox" name="driverLicense" onChange={handleChange} /> Driver License (DL)
      </label>
      <label>
        <input type="checkbox" name="ead" onChange={handleChange} /> Work ID (EAC)
      </label>
      <label>
        <input type="checkbox" name="ssn" onChange={handleChange} /> Social Security Number (SSN)
      </label>
      <label>
        <input type="checkbox" name="previousLandlordLetter" onChange={handleChange} /> Previous Landlord's Letter
      </label>

      {!formData.noCreditScore && (
        <input type="number" name="creditScore" placeholder="Credit Score" min="0" onChange={handleChange} required={!formData.noCreditScore} />
      )}
      <label>
        <input type="checkbox" name="noCreditScore" onChange={handleChange} /> Don't Have Credit Score
      </label>

      {!formData.noIncomeTax && (
        <input type="number" name="incomeTax" placeholder="Income Tax" min="0" onChange={handleChange} required={!formData.noIncomeTax} />
      )}
      <label>
        <input type="checkbox" name="noIncomeTax" onChange={handleChange} /> Don't Have Income Tax
      </label>

      <input type="number" name="savings" placeholder="Savings" min="0" onChange={handleChange} />

      <div className='forms-title'>Tenants Photo</div>
      <div className='forms-text'>Upload a general photo of future tenants (where you can see everyone, some or just you)</div>
      <input id="upload-profile-photo" type="file" name="pfp" style={{ display: 'none' }} onChange={handleChange}/>
        <label htmlFor="upload-profile-photo" className="button-upload-login">
            Upload photo
        </label>
        {previewUrl && <img src={previewUrl} alt="Profile Preview" style={{ width: '100px', height: '100px', borderRadius: '12px', objectFit: 'cover' }} />}

      <button type="submit">Sign Up</button>
      <Link to="/login" className='signup-link' style={{ textDecoration: 'none', color: 'white', textAlign: 'center', marginTop: '30px' }}>Already have an account? Click to login.</Link>
    </form>
  );
}

export default SignUp;
