import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig.ts';

const adminUIDs = ['r5XE4OKvtWamTgoLkBu33badbQy2'];

const IfAdminToPanel = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user && adminUIDs.includes(user.uid)) {
            navigate('/admin');
          }
    });

    return () => unsubscribe();
  }, [navigate]);

  return children;
};

export default IfAdminToPanel;
