import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";
import { getStripePayments } from "@invertase/firestore-stripe-payments";

const firebaseConfig = {
    apiKey: "AIzaSyDzA0tG9TfNTfS-gUdnuaDWhUkBC5kBPk0",
    authDomain: "easyrental-92cda.firebaseapp.com",
    projectId: "easyrental-92cda",
    storageBucket: "easyrental-92cda.appspot.com",
    messagingSenderId: "386131006711",
    appId: "1:386131006711:web:d57433642c8f67ea052ff2",
    measurementId: "G-EW0N30K6E7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });
// const analytics = getAnalytics(app);

export { db, auth, storage, payments };
